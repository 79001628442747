type NestedObject = {
  [key: string]: NestedObject | number | string | boolean | any[]; // Add more specific types as needed
};

export const getDeeplyNestedKeys = (obj: NestedObject, parentKey: string = ''): string[] => {
  const keys: string[] = [];

  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const path = parentKey ? `${parentKey}.${key}` : key;

      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // If the value is an object, recursively call the function
        keys.push(...getDeeplyNestedKeys(obj[key] as NestedObject, path));
      } else {
        // If the value is not an object, add the key to the result array
        keys.push(path);
      }
    }
  }

  return keys;
};
