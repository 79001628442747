import { memo, useState } from 'react';
import { useBlockFlags } from '@ContractBuilder/modules/block/context/context';
import { BlockContentDeleted } from '@ContractBuilder/modules/block-content/views/BlockContentDeleted';
import { ArtificialCustomEvent, useCustomEventListener } from '@ContractBuilder/modules/events';
import { InlineBlockEditController } from '@ContractBuilder/modules/inline-block-edit';
import { VariationsSelectorLite } from '@ContractBuilder/modules/variations-tabs/controller/VariationsSelectorLite';
import { useVariationsTabsStore } from '@ContractBuilder/modules/variations-tabs/store';
import { ctxIsPDF } from '@ContractBuilder/rules/block/is-ctx';
import { useEntityStore, useSchemaStore } from '@ContractBuilder/store';
import { getClausesAsMentions, getGroupedClauses } from '@ContractBuilder/utils';
import { getMentionValues } from '@ContractBuilder/utils/get-block-mention-values';
import { useDeepCompareMemo } from '@hooks/use-deep-compare-memo';
import type { ResourceBlock } from '@root/@types/base';
import { getDeeplyNestedKeys } from '@root/helpers';
import { getAllBlocks } from '@root/helpers/blocks';
import { useEffectOnce } from '@src/hooks';

import { BlockContent } from '../views/BlockContent';
import { BlockContentDiff } from '../views/BlockContentDiff';

const getBlockForDiff = (blockId: string, changedBlocks: ResourceBlock[] = []) =>
  changedBlocks.find((changedBlock) => changedBlock.id === blockId);

const BlockContentControllerInner = () => {
  const {
    block,
    isHighlighted,
    context,
    details: {
      isVisible,
      isEditing,
      variations: { count },
    },
  } = useBlockFlags();

  const isPDFRender = ctxIsPDF(context);

  const [shouldShowDiff, setShouldShowDiff] = useState(false);
  const { submission, endorsementParent } = useEntityStore(({ submission, endorsementParent }) => ({
    submission,
    endorsementParent,
  }));
  const { schemaTreeList } = useSchemaStore(({ schemaTreeList }) => ({ schemaTreeList }));
  const { setBlockSelectedVariationIdx } = useVariationsTabsStore(({ setBlockSelectedVariationIdx }) => ({
    setBlockSelectedVariationIdx,
  }));

  useCustomEventListener(ArtificialCustomEvent.ShowEndorsementDiff, setShouldShowDiff);

  useEffectOnce(() => {
    if (block.variations?.length) {
      setBlockSelectedVariationIdx(block);
    }
  });

  // We can't rely on previous block snapshot here because it's not always available (e.g. when only data_items have changed)
  const parentMentionValues = useDeepCompareMemo(() => {
    const usedClauses = getGroupedClauses(endorsementParent);
    const clausesList = getClausesAsMentions(usedClauses);

    const mentions = getMentionValues(
      endorsementParent?.data_items,
      getDeeplyNestedKeys(endorsementParent?.data_items ?? {}),
      schemaTreeList,
    );

    return { ...mentions, ...clausesList };
  }, [endorsementParent, schemaTreeList]);

  if (isEditing) {
    return <InlineBlockEditController />;
  }

  const blockForDiff = getBlockForDiff(block.id, submission?.changed_blocks);
  const shouldShowEndorsementDiff = shouldShowDiff || block?.isDeleted;

  if (shouldShowEndorsementDiff && blockForDiff) {
    const endorsementParentBlocks = getAllBlocks(endorsementParent);
    const parentBlock = endorsementParentBlocks.find((item) => item.id === blockForDiff.id);

    return (
      <BlockContentDiff
        block={blockForDiff}
        endorsement={submission}
        endorsementParent={endorsementParent}
        endorsementParentMentionValues={parentMentionValues}
        isPDFRender={isPDFRender}
        parentBlock={parentBlock}
        showEndorsementDiff={shouldShowEndorsementDiff}
      />
    );
  }

  if (!isPDFRender && count) {
    return <VariationsSelectorLite block={block} />;
  }

  if (isPDFRender && block.isDeleted) {
    return <BlockContentDeleted block={block} isHighlighted={isHighlighted} isPdfRender={isPDFRender} />;
  }

  return (
    <BlockContent
      block={block}
      isVisible={isVisible}
      isEditing={isEditing}
      isHighlighted={isHighlighted}
      isPdfRender={isPDFRender}
      isInCreateMode={isEditing && !block?.id}
      showEndorsementDiff={shouldShowEndorsementDiff}
    />
  );
};

export const BlockContentController = memo(BlockContentControllerInner);
