import axios from 'axios';

import type { Submission } from '../../@types/types';
import { getRequestAuth } from '../helpers/getRequestAuth';

export const fetchSubmissions = async (authToken: string): Promise<Submission[]> => {
  const auth = await getRequestAuth(authToken);

  const params = new URLSearchParams();

  const response = await axios({
    method: 'get',
    url: `${import.meta.env.VITE_API_URL}/submissions-spa-al`,
    params,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
  });

  return response.data;
};
