import React, { useEffect, useRef, useState } from 'react';
import { InputField, type InputFieldProps } from '@components/InputField';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import type { DownshiftState, StateChangeOptions } from 'downshift';
import Downshift from 'downshift';

import type { UIComponentBase, UIInputValue, UIOnChangeFn, UISelectionType } from '../../@types/types';

import { CloseIconButton } from './atoms/ClearInputButton';
import { selectTriggerSizes } from './classes/select';
import Icon from './Icon';
import InputReadOnly from './InputReadOnly';
import { MAX_DROPDOWN_HEIGHT_PX } from './shared';

type InputSelectStyle = 'button' | 'input' | 'menuInner' | 'menuItem' | 'menuOuter' | 'menuItemDisabled';
type InputSelectStyles = Record<InputSelectStyle, string>;

// eslint-disable-next-line react-refresh/only-export-components
export const inputSelectStyles: InputSelectStyles = {
  button:
    'bg-white border border-info-300 px-3 py-2 rounded text-info-900 h-10 flex justify-between items-center w-full focus:outline-none focus:shadow-outline',
  input: 'border-b border-info-100 px-3 py-2 text-info-900 w-full focus:outline-none',
  menuInner: 'overflow-y-auto list-none',
  menuItem: 'py-2 px-3 cursor-pointer text-sm font-sans truncate',
  menuOuter:
    'border-info-300 border rounded absolute inset-x-0 z-[21] top-0 bg-white text-info-900 mt-11 shadow overflow-hidden w-full',
  menuItemDisabled: 'cursor-not-allowed bg-info-50 text-info-300',
};

const itemClasses = cva(inputSelectStyles.menuItem, {
  variants: {
    isEmpty: { true: 'text-info-400' },
    isDisabled: { true: inputSelectStyles.menuItemDisabled },
    isHighlighted: { true: 'bg-info-100' },
  },
});

const buttonClasses = cva(
  [
    //
    'm-auto',
    'flex',
    'flex-1',
    'justify-between',
    'rounded-md',
    'text-sm',
    'text-info-900 ',
  ],
  {
    variants: {
      size: selectTriggerSizes,
      isUnstyled: { true: '', false: 'border border-info-300 shadow-sm' },
      isDisabled: {
        true: 'cursor-not-allowed opacity-50',
      },
      isIncomplete: {
        true: 'field-incomplete',
      },
    },
    defaultVariants: {
      isUnstyled: false,
    },
  },
);

export interface InputSelectProps<T = UIInputValue> extends InputFieldProps {
  'data-testid'?: string;
  id?: string;
  isClearable?: boolean;
  isReadOnly?: boolean;
  isSearchable?: boolean;
  labelText?: string;
  name?: string;
  options?: UISelectionType[];
  value?: T;
  initialSelectedItem?: T;
  placeholder?: string;
  placeholderClassName?: string;
  'aria-labelledby'?: string;
  'aria-label'?: string;
  formatter?: (value: any) => string | number | false;
  itemFormatter?: (value: any) => string;
  onChange?: UIOnChangeFn<T>;
  menuOuterClassName?: string;
  unstyled?: boolean;
  limitValueLengthPx?: number;
  inputClassName?: string;
}

export interface MenuContainerProps {
  anchorRef?: React.RefObject<HTMLInputElement>;
  children?: UIComponentBase['children'];
  className?: string;
}

export type UseDownshiftStyleState = React.CSSProperties | undefined;

export type StateReducerFn =
  | ((
      state: DownshiftState<UISelectionType>,
      changes: StateChangeOptions<UISelectionType>,
    ) => Partial<StateChangeOptions<UISelectionType>>)
  | undefined;

const useDownshiftStyle = (
  anchorRef?: React.RefObject<HTMLInputElement>,
  ref?: React.RefObject<HTMLInputElement>,
): UseDownshiftStyleState => {
  const [style, setStyle] = useState<UseDownshiftStyleState>(undefined);

  useEffect(() => {
    const element = ref?.current;
    const anchorElement = anchorRef?.current;

    if (element && anchorElement) {
      const anchor = anchorElement.getBoundingClientRect();
      const bound = element.getBoundingClientRect();

      // if overflowing
      if (anchor.y + anchor.height > window.innerHeight - MAX_DROPDOWN_HEIGHT_PX) {
        const height = bound.bottom - bound.y + 8; // get element height
        const style = { marginTop: -height };
        setStyle({ ...style });
      }
    }
  }, [anchorRef, ref]);

  return style;
};

const downshiftStateReducer: StateReducerFn = (_state, changes) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
      return { ...changes, inputValue: '' };

    case Downshift.stateChangeTypes.clickButton:
    case Downshift.stateChangeTypes.changeInput:
      return { ...changes, highlightedIndex: 0 };

    default:
      return changes;
  }
};

export const InputSelectMenuContainer: React.FC<MenuContainerProps> = ({
  anchorRef,
  children,
  className = inputSelectStyles.menuOuter,
}) => {
  const ref = useRef(null);
  const style = useDownshiftStyle(anchorRef, ref);

  return (
    <div className={className} ref={ref} style={style}>
      {children}
    </div>
  );
};

const formatSelectValue = (value: UIInputValue, options: UISelectionType[] = []) => {
  const selected = options.find((option) => option.value === value);
  return selected?.name ?? value;
};

const getItemToString = (item: UISelectionType | null): string => {
  return (item?.value ?? '').toString();
};

const InputSelect = <T extends UIInputValue = string>({
  className,
  'data-testid': dataTestId = 'dropdown-select',
  placeholderClassName,
  descriptionText,
  errors,
  isClearable = false,
  isReadOnly,
  isSearchable = true,
  labelText,
  name = 'input',
  options,
  placeholder = 'Select',
  size = 'md',
  value,
  id,
  formatter,
  itemFormatter,
  onChange,
  menuOuterClassName,
  unstyled,
  limitValueLengthPx,
  inputClassName,
  isIncomplete,
  hideErrors,
  ...props
}: InputSelectProps<T>) => {
  const getReadOnlyItem = (value: UIInputValue) => {
    const selected = options?.find((option) => option.value === value);
    return selected?.name ?? value;
  };

  const handleRemoveSelection: React.HTMLAttributes<HTMLDivElement>['onClick'] = (event) => {
    event.stopPropagation();
    return onChange?.(undefined, name);
  };

  return (
    <InputField
      {...props}
      className={className}
      descriptionText={descriptionText}
      labelText={labelText}
      name={name}
      errors={errors}
      hideErrors={hideErrors}
      isIncomplete={isIncomplete}
    >
      {isReadOnly && (
        <InputReadOnly
          {...props}
          aria-describedby={descriptionText ? `${name}Description` : undefined}
          name={name}
          value={getReadOnlyItem(value) || ''}
        />
      )}

      {!isReadOnly && (
        <Downshift
          stateReducer={downshiftStateReducer}
          // @ts-expect-error
          selectedItem={value ?? ''}
          itemToString={getItemToString}
          onSelect={(item: any) => {
            if (onChange) onChange(item?.value, name);
          }}
        >
          {({
            getItemProps,
            getMenuProps,
            getToggleButtonProps,
            highlightedIndex,
            isOpen,
            inputValue,
            getInputProps,
          }) => {
            const normalized = String(inputValue ?? '').toLowerCase();
            const byInputValue = (item: UISelectionType) =>
              String(item.name ?? '')
                .toLowerCase()
                .includes(normalized);
            const filteredItems = options?.filter(byInputValue);
            const selectableItems = isSearchable ? filteredItems : options;
            const hasSelection = Boolean(value);

            return (
              <div className="relative flex text-sm">
                <button
                  id={`${id}-select-trigger`}
                  data-testid={dataTestId}
                  disabled={props.isDisabled}
                  {...getToggleButtonProps()}
                  aria-label={labelText ?? props['aria-label']}
                  aria-labelledby={props['aria-labelledby']}
                  type="button"
                  className={buttonClasses({
                    size,
                    isUnstyled: unstyled,
                    isDisabled: props.isDisabled,
                    isIncomplete,
                    className: [inputClassName],
                  })}
                >
                  {hasSelection ? (
                    <span
                      style={{
                        maxWidth: limitValueLengthPx,
                      }}
                      className={clsx(limitValueLengthPx && 'truncate whitespace-nowrap')}
                    >
                      {formatter ? formatter(value) : formatSelectValue(value, options)}
                    </span>
                  ) : (
                    <span className={clsx('text-info-400', placeholderClassName)}>{placeholder}</span>
                  )}
                  <div className="my-auto inline-flex">
                    {isClearable && hasSelection && (
                      <CloseIconButton content="Remove selection" onClick={handleRemoveSelection} />
                    )}
                    {!isOpen && <Icon name="chevron-down" className="ml-2 self-center fill-current text-info-800" />}
                    {isOpen && <Icon name="chevron-up" className="ml-2 self-center fill-current text-primary-700" />}
                  </div>
                </button>

                {isOpen && (
                  <InputSelectMenuContainer className={clsx(inputSelectStyles.menuOuter, menuOuterClassName)}>
                    <div {...getMenuProps()} data-testid="downshift-list">
                      {isSearchable && (
                        <input
                          autoFocus
                          className={clsx(inputSelectStyles.input, inputClassName)}
                          placeholder="Search..."
                          {...getInputProps()}
                        />
                      )}

                      <ul className={inputSelectStyles.menuInner} style={{ maxHeight: MAX_DROPDOWN_HEIGHT_PX }}>
                        {(selectableItems || []).map((item, index) => {
                          const { isDisabled = false } = item;
                          const isEmpty = item.value === undefined;
                          const result =
                            (itemFormatter && itemFormatter(item.value)) ||
                            (formatter && formatter(item.value)) ||
                            item.name;

                          const { onClick, onMouseDown, onMouseMove, ...itemProps } = getItemProps({ item });

                          const actionProps = isDisabled
                            ? {}
                            : {
                                onClick,
                                onMouseDown,
                                onMouseMove,
                              };

                          return (
                            <li
                              data-testid={item.name}
                              className={itemClasses({
                                isEmpty,
                                isDisabled: isDisabled && !isEmpty,
                                isHighlighted: index === highlightedIndex,
                              })}
                              key={item.value ? JSON.stringify(item.value) : `select-${id}-${index}`}
                              {...actionProps}
                              {...itemProps}
                            >
                              {result}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </InputSelectMenuContainer>
                )}
              </div>
            );
          }}
        </Downshift>
      )}
    </InputField>
  );
};

export default InputSelect;
