import { getRequestAuth } from '@helpers/getRequestAuth';
import type { UpdateBlockInLibraryPayload } from '@root/@types/types';
import axios from 'axios';

export const createBlock = async (data: UpdateBlockInLibraryPayload, authToken = ''): Promise<{ id: string }> => {
  const auth = await getRequestAuth(authToken);

  const response = await axios({
    method: 'post',
    url: `${import.meta.env.VITE_API_URL}/blocks${auth.authProvider === 'al' ? '-spa-al' : ''}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
    data,
  });

  return response.data;
};
