import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import InputMultiSelect from '@components/InputMultiSelect/InputMultiSelect';
import Tooltip from '@components/Tooltip';
import { ITEM_TAGS_Z_INDEX } from '@constants/z-indices';
import { useEntityStore } from '@ContractBuilder/store';
import type { EntityData } from '@ContractBuilder/types';
import { CheckIcon, PencilIcon, XIcon } from '@heroicons/react/outline';
import { fetchEntity } from '@queries/fetchEntity';
import type { SetIsLoading, Tag } from '@root/@types/types';
import { isNonNullish } from '@root/helpers';
import clsx from 'clsx';
import { get } from 'lodash-es';
import useDeepCompareEffect from 'use-deep-compare-effect';

interface ItemTagsProps {
  item: EntityData;
  setIsLoading?: SetIsLoading;
  tags: Tag[];
  queryKey: string;
}

const ItemTags: FC<ItemTagsProps> = ({ item, setIsLoading, tags, queryKey }) => {
  const [updatedTagsIds, setUpdatedTagsIds] = useState<string[]>();
  const initialTagsLabels = (updatedTagsIds ?? get(item, 'tags', []))
    ?.map((tagId: string) => tags.find((tag: Tag) => tag.id === tagId)?.label)
    .filter(isNonNullish);
  const [itemTagsLabels, setItemTagsLabels] = useState<string[]>(initialTagsLabels);
  const [isUpdatingItemTags, setIsUpdatingItemTags] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const queryClient = useQueryClient();

  const updateSubmission = useEntityStore(({ updateResourceData }) => updateResourceData);

  useEffect(() => {
    if (typeof setIsLoading === 'function') {
      setIsLoading(isUpdatingItemTags);
    }
  }, [isUpdatingItemTags, setIsLoading]);

  useDeepCompareEffect(() => {
    setItemTagsLabels(initialTagsLabels);
  }, [initialTagsLabels, tags.length]);

  const toggleEditMode = () => setIsEditMode(!isEditMode);
  const handleChangeItemTags = (itemTagsLabels: string[] | undefined) => setItemTagsLabels(itemTagsLabels ?? []);
  const handleCancelUpdateItemTags = () => {
    setItemTagsLabels(initialTagsLabels);
    toggleEditMode();
  };

  const handleUpdateItemTags = async () => {
    const payload: string[] = itemTagsLabels
      ?.map((label) => tags.find((i) => i.label === label)?.id)
      .filter(isNonNullish);

    try {
      setIsUpdatingItemTags(true);

      await updateSubmission({ tags: payload }, item.id);
      const { tags: tagsResponse = [] } = await fetchEntity({ dataRange: [], entityId: item.id });
      await queryClient.invalidateQueries({ queryKey: [queryKey] });

      setUpdatedTagsIds(tagsResponse);
      toggleEditMode();
    } catch (e) {
      setItemTagsLabels(initialTagsLabels);
    } finally {
      setIsUpdatingItemTags(false);
    }
  };

  return isEditMode ? (
    <td>
      <div className="item-tags-edit-mode xl:block xl:pt-1 2xl:flex 2xl:items-center">
        <InputMultiSelect
          value={itemTagsLabels}
          options={tags.map((i: Tag) => i.label)}
          onChange={handleChangeItemTags}
          onSelect={() => {}}
          isDisabled={isUpdatingItemTags}
          className="w-auto"
          multiSelectDropdownClasses="!min-w-none !sm:max-w-[2rem] !md:max-w-[3rem] !lg:max-w-[4rem] !xl:max-w-[5rem] !2xl:max-w-[6rem] !max-w-[6rem]"
          dropdownInnerWrapperClassName="sm:max-w-[2rem] md:max-w-[2rem] lg:max-w-[2rem] xl:max-w-[4rem] 2xl:max-w-[5rem] max-w-[5rem]"
          selectedItemClassName="sm:max-w-[2rem] md:max-w-[3rem] lg:max-w-[4rem] xl:max-w-[5rem] 2xl:max-w-[6rem] max-w-[6rem] flex"
          selectedItemLabelClassName="truncate block sm:max-w-[2rem] md:max-w-[3rem] lg:max-w-[4rem] xl:max-w-[5rem] 2xl:max-w-[6rem] max-w-[6rem]"
        />
        <div
          className={clsx(
            'float-left block cursor-pointer rounded-full p-1 text-info-500 hover:bg-info-50',
            ITEM_TAGS_Z_INDEX,
          )}
          onClick={handleCancelUpdateItemTags}
        >
          <XIcon className="h-4 w-4" />
        </div>
        <div
          className={clsx(
            'float-left block cursor-pointer rounded-full p-1 text-info-500 hover:bg-info-50',
            ITEM_TAGS_Z_INDEX,
          )}
          onClick={handleUpdateItemTags}
        >
          <CheckIcon className="h-4 w-4" />
        </div>
      </div>
    </td>
  ) : (
    <td className="group py-4 align-top">
      {itemTagsLabels?.map((tagLabel: string) => (
        <Tooltip content={tagLabel} key={tagLabel}>
          <span className="inline-flex max-w-[2rem] items-center rounded-full bg-info-100 px-2.5 py-0.5 text-xs font-medium text-info-800 sm:max-w-[2rem] md:max-w-[3rem] lg:max-w-[4rem] xl:max-w-[7rem] 2xl:max-w-[10rem]">
            <span className="truncate">{tagLabel}</span>
          </span>
        </Tooltip>
      ))}
      <span
        className="invisible inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium text-info-800 opacity-0 group-hover:visible group-hover:opacity-100"
        onClick={toggleEditMode}
      >
        <span className="cursor-pointer rounded-full hover:text-info-500">
          <PencilIcon className="h-4 w-4" />
        </span>
      </span>
    </td>
  );
};

export default ItemTags;
