import type { EntityData } from '@ContractBuilder/types';
import type { ResourceBlock } from '@root/@types/base';

import { getSortedEntries } from '../layout';

/** Get all blocks ordered exactly how they appear in the document, from top to bottom **/
const getEntitySortedBlocks = (entity: EntityData) => {
  const sortedSections = getSortedEntries(entity.layout, entity.sections);
  return sortedSections.flatMap((section) => getSortedEntries(section.layout, section.blocks));
};

export const getAllBlocks = (entity?: EntityData): ResourceBlock[] => {
  if (!entity?.sections) {
    return [];
  }

  return getEntitySortedBlocks(entity);
};
