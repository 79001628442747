import React from 'react';
import { EDIT_TOOLBAR_Z_INDEX } from '@constants/z-indices';
import { INLINE_BLOCK_EDITOR_NAME, useEditorsMap } from '@ContractBuilder/modules/editors-map';
import { WysiwygControls } from '@WysiwygEditor/components';
import { getDatapointControls } from '@WysiwygEditor/components/Controls/utils/datapoint-controls';
import clsx from 'clsx';

import { TOOLBAR_HEIGHT } from '../../../settings';

export const EditorToolbarController = () => {
  const { getEditor } = useEditorsMap();

  const editor = getEditor(INLINE_BLOCK_EDITOR_NAME);
  const isDisabled = !editor;

  if (isDisabled) {
    return null;
  }

  return (
    <div className={clsx('mx-auto flex w-full items-center border-b bg-white py-2 pl-4 text-center', TOOLBAR_HEIGHT)}>
      <WysiwygControls
        className={clsx('grow border-none', EDIT_TOOLBAR_Z_INDEX)}
        datapointsControl={getDatapointControls}
        editor={editor}
      />
    </div>
  );
};
