import type { TransformedCognitoUser } from '@root/@types/cognito';

import { UserRole } from '../../@types/types';

export const getUserAttribute = (user: any, attrName: string, keyName: string) =>
  user?.[keyName]?.find((attr: any) => attr.Name === attrName) || {};

/**
 * Used in FE
 * @param amplifyUser
 * @returns
 */
export const cognitoUserAttributes = (amplifyUser: any) => {
  const attributes = amplifyUser?.signInUserSession?.idToken?.payload;

  return {
    given_name: attributes?.given_name ?? '',
    family_name: attributes?.family_name ?? '',
    email: attributes?.email,
    sub: attributes?.sub,
    identities: attributes?.identities,
    'custom:tenantId': attributes?.['custom:tenantId'],
    'custom:tenant_ids': attributes?.['custom:tenant_ids'],
    'custom:role': attributes?.['custom:role'] || UserRole.User,
  };
};

/**
 * Used in BE
 * @param ddbUser
 * @param cognitoUser
 * @param keyName
 * @returns
 */
export const transformUser = (ddbUser: any, cognitoUser: any, keyName = 'Attributes'): TransformedCognitoUser => {
  let cognitoTeams = [];
  try {
    cognitoTeams = JSON.parse(getUserAttribute(cognitoUser, 'custom:tenant_ids', keyName)?.Value || '[]');
    if (!Array.isArray(cognitoTeams)) {
      cognitoTeams = [];
    }
  } catch (e) {
    cognitoTeams = [];
  }
  const ddbTeams = ddbUser?.teams ?? [];

  return {
    id: ddbUser?.id || cognitoUser?.id,
    username: cognitoUser?.Username || ddbUser.id,
    enabled: cognitoUser?.Enabled,
    status: cognitoUser?.UserStatus,
    created_at: ddbUser?.createdAt || cognitoUser?.UserCreateDate,
    updated_at: ddbUser?.updatedAt || cognitoUser?.UserLastModifiedDate,
    sub: getUserAttribute(cognitoUser, 'sub', keyName)?.Value,
    first_name: ddbUser?.firstName || getUserAttribute(cognitoUser, 'given_name', keyName)?.Value,
    last_name: ddbUser?.lastName || getUserAttribute(cognitoUser, 'family_name', keyName)?.Value,
    email: ddbUser?.email || getUserAttribute(cognitoUser, 'email', keyName)?.Value,
    role: ddbUser?.role || getUserAttribute(cognitoUser, 'custom:role', keyName)?.Value,
    tenant_ids: [],
    teams: Array.isArray(ddbTeams) ? (ddbTeams.length !== 0 ? ddbTeams : cognitoTeams) : cognitoTeams,
    identity_provider: ddbUser?.identityProvider || 'internal',
  };
};

export const randPolicyCompliantChars = () => {
  const rand = (items: any) => items[Math.floor(Math.random() * items.length)];
  const specialChars = '!_~()@*;';
  const upperChar = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerChar = 'abcdefghijklmnopqrstuvwxyz';
  const number = '0123456789';

  return rand(specialChars) + rand(upperChar) + rand(lowerChar) + rand(number);
};
