import { useEffect, useRef } from 'react';
import { useSchemaStore } from '@ContractBuilder/store';
import type { EntityData } from '@ContractBuilder/types';
import type { UIInputValue } from '@root/@types/types';
import { resolveSchemaTreeFromForm } from '@root/helpers/schema';
import schema from '@root/helpers/schema/schema-cdr.json';
import { useFuseSearch } from '@src/hooks';
import { Bold } from '@tiptap/extension-bold';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import { Italic } from '@tiptap/extension-italic';
import Placeholder from '@tiptap/extension-placeholder';
import Strike from '@tiptap/extension-strike';
import TableHeader from '@tiptap/extension-table-header';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import type { Editor } from '@tiptap/react';
import { ReactRenderer, useEditor as tiptapUseEditorHook } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { DatapointsInserterExpanded } from '@WysiwygEditor/components/DatapointInserter/DatapointsInserterExpanded';
import { debounce } from 'lodash-es';
import { Fragment, Slice } from 'prosemirror-model';
import tippy from 'tippy.js';

import { InsertMenuExpandedInner } from '../../InsertMenu';
import {
  ContentRepeaterGroup,
  ContentRepeaterGroupChild,
  FontSize,
  Indent,
  ListItem,
  Mention,
  OrderedList,
  Paragraph,
  SectionRepeater,
  SectionRepeaterChild,
  Subscript,
  Superscript,
  Table,
  TableCell,
  TableRow,
  UppercaseMark,
} from '../extensions';

export interface UseEditorHookProps {
  documentType: string;
  name: string;
  editorContent?: { [name: string]: UIInputValue };
  editable?: boolean;
  unformattedPaste?: boolean;
  onUpdate: (content: string) => void;
  placeholderText?: string;
  submission?: EntityData;
  enableDatapointsInsert?: boolean;
}

export const useEditor = ({
  documentType,
  name,
  editorContent = {},
  enableDatapointsInsert = true,
  onUpdate,
  editable,
  unformattedPaste = false,
  placeholderText = 'Block content',
  submission,
}: UseEditorHookProps): Editor | null => {
  const { schemaTreeList } = useSchemaStore((s) => s);

  const initialOptions =
    schemaTreeList?.map(({ id, title }) => ({
      label: title as string,
      value: id,
    })) ?? [];

  const resultRef = useRef<{ label: string; value: string }[]>(initialOptions);

  const userContent = editorContent[name] ?? '';
  const unformattedPasteRef = useRef(unformattedPaste);

  const { onSearch, searchResult } = useFuseSearch({ options: initialOptions });

  useEffect(() => {
    unformattedPasteRef.current = unformattedPaste;
  }, [unformattedPaste]);

  useEffect(() => {
    resultRef.current = searchResult;
  }, [searchResult]);

  const handleEditorUpdate = debounce(async (content) => {
    await onUpdate(content);
    // previous 200ms causes fail in cypress tests (content is not updated in state)
  }, 10);

  const editor = tiptapUseEditorHook(
    {
      disablePasteRules: true, // disable Markdown when pasting
      disableInputRules: true, // disable Markdown when typing
      autofocus: true,
      onUpdate({ editor }: any) {
        handleEditorUpdate(editor.getHTML());
      },
      editorProps: {
        attributes: {
          class: `wysiwyg ${documentType.toLowerCase()} prose prose-sm sm:prose lg:prose-lg focus:outline-none`,
        },
        handlePaste(view: any, event: any, _slice: unknown) {
          if (unformattedPasteRef.current) {
            const text = event?.clipboardData?.getData('text/plain');
            const textFragment = Fragment.from(view.state.schema.text(text));
            const textSlice = new Slice(textFragment, 0, 0);
            //https://github.com/ProseMirror/prosemirror-view/blob/75c930281c4c45c214df750dd367c17efc315b8f/src/input.js#L542
            const singleNode =
              textSlice.openStart == 0 && textSlice.openEnd == 0 && textSlice.content.childCount == 1
                ? textSlice.content.firstChild
                : null;
            //https://github.com/ProseMirror/prosemirror-view/blob/75c930281c4c45c214df750dd367c17efc315b8f/src/input.js#L566
            const tr = singleNode
              ? view.state.tr.replaceSelectionWith(singleNode, (view as any).shiftKey)
              : view.state.tr.replaceSelection(textSlice);
            view.dispatch(tr.scrollIntoView().setMeta('paste', true).setMeta('uiEvent', 'paste'));
            return true;
          } else return false;
        },
      },
      extensions: [
        StarterKit.configure({
          orderedList: false,
          listItem: false,
          hardBreak: false,
          bold: false,
          code: false,
          codeBlock: false,
          horizontalRule: false,
          blockquote: false,
          strike: false,
          heading: false,
          italic: false,
          paragraph: false,
        }),
        Placeholder.configure({
          placeholder: placeholderText,
        }),
        Underline,
        Table(),
        TableRow(),
        TableHeader,
        OrderedList,
        ListItem,
        Indent,
        Paragraph,
        FontSize,
        Heading.extend({
          addAttributes() {
            return {
              dataType: {
                default: null,
                parseHTML: (element: HTMLElement) => element.getAttribute('data-type'),
                renderHTML: (attributes: Record<string, any>) => {
                  return {
                    'data-type': attributes.dataType,
                  };
                },
              },
              level: {
                default: 1,
                rendered: false,
              },
            };
          },
          addInputRules() {
            return [];
          },
        }),
        UppercaseMark,
        HardBreak.extend({
          addKeyboardShortcuts() {
            return {
              'Shift-Enter': () => this.editor.commands.setHardBreak(),
            };
          },
        }).configure({
          keepMarks: false,
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        TextStyle,
        TableCell(),
        Bold.extend({
          addInputRules() {
            return [];
          },
        }),
        Italic.extend({
          addInputRules() {
            return [];
          },
        }),
        enableDatapointsInsert &&
          Mention().configure({
            HTMLAttributes: {
              class: 'mention',
            },
            suggestion: {
              char: '{{',
              items: ({ query }: { query: string }) => {
                onSearch(query);
                return resultRef.current;
              },
              render: () => {
                let reactRenderer: ReactRenderer<typeof InsertMenuExpandedInner, any>;
                let popup: any;

                return {
                  onStart: (props: any) => {
                    const resolvedSchemaTree = resolveSchemaTreeFromForm(schema, submission?.data_items);
                    reactRenderer = new ReactRenderer(InsertMenuExpandedInner, {
                      props: {
                        ...props,
                        className: 'max-h-[364px]',
                        label: 'Data points',
                        isSearchable: false,
                        items: initialOptions.map((item) => ({
                          ...item,
                          id: item.value,
                        })),
                        onCancel: () => popup?.[0]?.hide(),
                        renderExpanded: (renderExpandedProps: any) => (
                          <DatapointsInserterExpanded
                            editor={props.editor}
                            onClose={() => popup?.[0]?.hide()}
                            resolvedSchemaTree={resolvedSchemaTree[0]}
                            search={props.query}
                            {...renderExpandedProps}
                            titleTextClasses="max-w-[330px] truncate"
                            sectionRepeaterTitleTextClasses="max-w-[225px] truncate"
                          />
                        ),
                      },
                      editor: props.editor,
                    });

                    popup = tippy('body', {
                      getReferenceClientRect: props.clientRect,
                      content: reactRenderer.element,
                      showOnCreate: true,
                      interactive: true,
                      trigger: 'manual',
                      placement: 'bottom-start',
                      theme: 'mention-tooltip',
                      arrow: false,
                      sticky: false,
                    });
                  },

                  onUpdate(props: any) {
                    const parsedItems = props.items.map((item: any) => ({
                      ...item,
                      id: item.value,
                    }));
                    reactRenderer?.updateProps({ ...props, items: parsedItems, search: props.query });

                    popup[0].setProps({
                      getReferenceClientRect: props.clientRect,
                    });
                  },
                  onKeyDown(props: any) {
                    //@ts-expect-error
                    return !!reactRenderer?.ref.handleKeyDown(props.event);
                  },
                  onExit() {
                    popup[0].destroy();
                    reactRenderer?.destroy();
                  },
                };
              },
            },
          }),
        Strike.extend({
          addInputRules() {
            return [];
          },
        }),
        Subscript,
        Superscript,
        SectionRepeater,
        SectionRepeaterChild,
        ContentRepeaterGroup,
        ContentRepeaterGroupChild,
      ],
    },
    [],
  );

  // https://github.com/ueberdosis/tiptap/issues/3764#issuecomment-1548336990
  useEffect(() => {
    if (editor) {
      setTimeout(() => {
        (editor as any).commands.setContent(userContent);
      }, 1);
    }
    // eslint-disable-next-line -- Update content only on editor mount
  }, [editor]);

  if (editor) {
    (editor as any).setOptions({ editable });
  }

  return editor;
};
