import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { useRemoveGlobalLoader } from '@hooks/useRemoveGlobalLoader';
import { cognitoUserAttributes } from '@root/functions/common/users-helper';
import * as Sentry from '@sentry/browser';
import { useEffectOnce } from '@src/hooks';
import { isEmpty } from 'lodash-es';

import { useUserStore } from './store';

export const PrivateRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { isLoggedIn, setUser } = useUserStore(({ isLoggedIn, setUser }) => ({
    isLoggedIn,
    setUser,
  }));

  const removeGlobalLoader = useRemoveGlobalLoader();

  useEffectOnce(() => {
    (async () => {
      const currentUserInfo = await Auth.currentUserInfo();
      const hasUserInfo = !isEmpty(currentUserInfo);

      if (hasUserInfo) {
        const amplifyUser = await Auth.currentAuthenticatedUser();
        currentUserInfo.attributes = cognitoUserAttributes(amplifyUser);
      }

      setIsLoading(false);
      setUser(hasUserInfo ? currentUserInfo : null);

      if (hasUserInfo) {
        Sentry.setUser({
          id: currentUserInfo.username,
          email: currentUserInfo.attributes.email,
        });
      }
    })();
  });

  useEffect(() => {
    if (!isLoading) {
      removeGlobalLoader();
    }
  }, [isLoading, removeGlobalLoader]);

  if (isLoading) {
    return null;
  }

  return isLoggedIn ? <Outlet /> : <Navigate to="/login" state={{ from: location }} />;
};
