import React, { forwardRef } from 'react';
import { cva } from 'class-variance-authority';

import type { ElementSize } from '../../@types/types';

import { ELEMENT_SIZES } from './shared';

const classes = cva(
  [
    'w-full',
    'appearance-none',
    'rounded-md',
    'border',
    'border-info-300',
    'px-3',
    'py-2',
    'text-sm',
    'text-info-700',
    'shadow-sm',
    'focus:border-primary-500',
    'focus:ring-primary-500',
  ],
  {
    variants: {
      isIncomplete: { true: 'field-incomplete' },
      isDisabled: { true: 'focus:ring-0' },
      isReadOnly: { true: 'focus:ring-0' },
      size: ELEMENT_SIZES,
    },
  },
);

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  errors?: string | string[];
  iconName?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  placeholderText?: string;
  size?: ElementSize;
  isIncomplete?: boolean;
}

const Input = forwardRef<any, InputProps>(
  (
    { className, id, isIncomplete, isDisabled, isReadOnly, isRequired, name, placeholderText, size = 'md', ...props },
    ref,
  ) => (
    <input
      className={classes({
        isDisabled,
        isIncomplete,
        isReadOnly,
        size,
        className,
      })}
      disabled={isDisabled}
      id={id ?? name}
      name={name}
      placeholder={placeholderText}
      readOnly={isReadOnly}
      ref={ref}
      required={isRequired}
      {...props}
    />
  ),
);

Input.displayName = 'Input';

export default Input;
