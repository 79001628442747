import type { FC } from 'react';
import React from 'react';
import { FieldSchemaIdTooltip } from '@components/FieldCdrId';
import Icon from '@components/Icon';
import IconMdi from '@components/IconMdi';
import { Show } from '@components/Show';
import { TooltipedIcon } from '@components/TooltipedIcon';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { useFieldSchemaIds } from '@hooks/use-schema-ids';
import { mdiHelpCircleOutline, mdiMinus, mdiPlus } from '@mdi/js';
import type { JSONSchema } from '@root/@types/types';
import mapChildren from '@root/src/mapChildren';
import clsx from 'clsx';
import { cloneDeep, get } from 'lodash-es';

import resolveSchema from '../../../helpers/resolveSchema';
import Button from '../../Button';
import Card from '../../Card';
import ErrorText from '../../ErrorText';
import H4 from '../../H4';

import type { SectionRepeaterViewProps } from './types';

export const SectionRepeaterView: FC<SectionRepeaterViewProps> = ({
  cdrId,
  cdrName,
  mrcId,
  handleRemove,
  title,
  emptyContent,
  helperText,
  initialItems,
  schemaItem,
  className,
  id,
  item,
  ownKey,
  validationErrors,
  showQuestionKey,
  onChange,
  isReadOnly = false,
  isDisabled,
  total100Error,
  canAdd,
  handleAdd,
  hideErrors,
}) => {
  const { formValues } = useDatapointsStore(({ formValues }) => ({ formValues }));
  const shouldHideTitle = get(item, 'ui:hideTitle', false);
  const { schemaId, parsedCdrIds, parsedCdrNames } = useFieldSchemaIds({ cdrId, cdrName, mrcId });
  return (
    <div className={clsx('mb-5 rounded-lg p-6 py-6', className)}>
      <Show when={!shouldHideTitle}>
        <FieldSchemaIdTooltip schemaId={schemaId} cdrIds={parsedCdrIds} cdrNames={parsedCdrNames}>
          <H4 className="flex items-center justify-between">
            <div className="flex justify-start gap-2">
              {title}
              {helperText && (
                <TooltipedIcon
                  path={mdiHelpCircleOutline}
                  data-testid="select-variation"
                  placement="top"
                  content={helperText}
                />
              )}
            </div>
          </H4>
        </FieldSchemaIdTooltip>
      </Show>
      {emptyContent && (
        <div className="mb-4 flex items-center gap-x-3.5 rounded-md bg-info-50 px-3 py-4 text-xs font-medium text-info-600">
          <Icon name="book" />
          {emptyContent}
        </div>
      )}

      {initialItems.map((_lineItem, index) => {
        const key = `${ownKey}[${index}]`;
        const clonedSchema = cloneDeep(schemaItem);

        resolveSchema(clonedSchema, get(formValues, key));

        return (
          <Card
            className="mb-8"
            key={key}
            header={
              <Card.Header className="flex items-center justify-between p-0 pb-4">
                <div className="font-medium">
                  {item.items?.title} #{index + 1}
                </div>
              </Card.Header>
            }
          >
            {mapChildren({
              onChange,
              parentKey: key,
              parentSchema: clonedSchema as JSONSchema,
              validationErrors,
              showQuestionKey,
              isReadOnly,
              isDisabled,
              hideErrors,
            })}
          </Card>
        );
      })}
      {total100Error && <ErrorText className="mb-4">{total100Error.message}</ErrorText>}
      <div className="flex">
        {!isReadOnly &&
          (item?.minimum === undefined || item?.minimum < initialItems.length) &&
          initialItems.length > 0 && (
            <Button
              className="mr-4 h-10"
              kind="danger"
              data-testid={`${id}-remove-section-button`}
              onClick={() => handleRemove(initialItems.length - 1)}
            >
              <IconMdi path={mdiMinus} />
              {`Remove ${item.title ?? 'a section'}`}
            </Button>
          )}
        {!isReadOnly && canAdd && (
          <Button onClick={handleAdd} kind="primary" className="h-10">
            <IconMdi path={mdiPlus} />
            {`Add ${item.title ?? 'a section'}`}
          </Button>
        )}
      </div>
    </div>
  );
};
