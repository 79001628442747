import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import * as Sentry from '@sentry/browser';
import { useEffectOnce } from '@src/hooks';
import Nav from '@src/routes';

export const Logout = () => {
  const navigate = useNavigate();

  useEffectOnce(() => {
    const signOut = async () => {
      try {
        Sentry.setUser(null);
        await Auth.signOut();
      } finally {
        navigate(Nav.Login);
      }
    };

    signOut();
  });

  return (
    <div className="overlay">
      <div className="loading-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Logout;
