import { getRequestAuth } from '@helpers/getRequestAuth';
import axios from 'axios';

import type { TransformedCognitoUser } from '../../@types/cognito';

export const updateUser = async (
  id: string,
  data:
    | ((
        | Pick<TransformedCognitoUser, 'email' | 'first_name' | 'last_name' | 'role' | 'teams' | 'identity_provider'>
        | Pick<TransformedCognitoUser, 'enabled'>
      ) & {
        action: 'update' | 'change-state';
      })
    | { action: 'resend-invite' },
  authToken: string,
): Promise<TransformedCognitoUser> => {
  const auth = await getRequestAuth(authToken);

  const response = await axios({
    method: 'patch',
    url: `${import.meta.env.VITE_API_URL}/users-spa-${auth.authProvider}/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
    data,
  });

  return response.data;
};
