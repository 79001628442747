import type { FC, KeyboardEvent, MouseEvent } from 'react';
import { useMemo, useRef, useState } from 'react';
import { selectTriggerSizes } from '@components/classes/select';
import Icon from '@components/Icon';
import type { InputMultiSelectProps } from '@components/InputMultiSelect/InputMultiSelect';
import { InputSelectMenuContainer, inputSelectStyles } from '@components/InputSelect';
import { MAX_DROPDOWN_HEIGHT_PX } from '@components/shared';
import { useDidUpdateEffect } from '@src/hooks';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { useCombobox, useMultipleSelection } from 'downshift';
import { isEqual } from 'lodash-es';

const SELECT_LABEL_MAX_LENGTH = 20;

const inputClasses = cva(
  ['multi-select', 'flex', 'cursor-pointer ', 'justify-between', 'rounded-md', 'border', 'border-info-300'],
  {
    variants: {
      size: selectTriggerSizes,
      isDisabled: { true: 'cursor-not-allowed opacity-50' },
      isIncomplete: { true: 'field-incomplete' },
    },
  },
);

const selectedItemStyle = 'bg-info-200 rounded-md bg-rounded py-1 -my-1 pl-3 pr-2 mr-1 space-x-1 text-sm font-normal';
const formatItemLabel = (item: string, selectLabelMaxLength: number): string =>
  item?.length > selectLabelMaxLength ? `${item.slice(0, selectLabelMaxLength)}...` : item;

type InputMultiSelectInnerProps = Pick<
  InputMultiSelectProps,
  | 'isDisabled'
  | 'isSearchable'
  | 'name'
  | 'onChange'
  | 'options'
  | 'placeholder'
  | 'selectLabelMaxLength'
  | 'size'
  | 'value'
  | 'menuOuterClassName'
  | 'selectedItemClassName'
  | 'selectedItemLabelClassName'
  | 'dropdownWrapperClassName'
  | 'dropdownInnerWrapperClassName'
  | 'multiSelectDropdownClasses'
  | 'isIncomplete'
>;

const getDefaultInitialSelectedItems = (value: unknown) => (typeof value === 'string' && !!value ? [value] : []);

const isEnterKey = (event: KeyboardEvent) => event.key === 'Enter';

export const InputMultiSelectInner: FC<InputMultiSelectInnerProps> = ({
  isDisabled,
  isSearchable,
  name = 'multiselect',
  onChange,
  options,
  placeholder,
  selectLabelMaxLength = SELECT_LABEL_MAX_LENGTH,
  size = 'md',
  value = [],
  menuOuterClassName = '',
  selectedItemClassName = '',
  selectedItemLabelClassName = '',
  dropdownWrapperClassName = '',
  dropdownInnerWrapperClassName = '',
  multiSelectDropdownClasses = '',
  isIncomplete,
}) => {
  const [inputValue, setInputValue] = useState<string | undefined>('');
  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
    setSelectedItems,
  } = useMultipleSelection({
    initialSelectedItems: Array.isArray(value) ? value : getDefaultInitialSelectedItems(value),
  });

  const getFilteredItems = (items: string[]) =>
    items.filter(
      (item) =>
        selectedItems.indexOf(item) < 0 &&
        String(item ?? '')
          .toLowerCase()
          .includes(String(inputValue ?? '').toLowerCase()),
    );

  const inputRef = useRef(null);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    toggleMenu,
  } = useCombobox<string | null>({
    items: getFilteredItems(options),
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue('');
            addSelectedItem(selectedItem);
            selectItem(null);
          }

          break;
        default:
          break;
      }
    },
  });

  const isOther = useMemo(() => selectedItems?.includes('Other'), [selectedItems]);

  const handleRemoveSelectedItem = (selectedItem: string) => {
    removeSelectedItem(selectedItem);
    if (Array.isArray(selectedItems) && selectedItems.length === 1 && selectedItems.indexOf(selectedItem) !== -1) {
      onChange(undefined, name);
    }
  };

  const isFull = getFilteredItems(options).length === 0;
  const isDropdownOpen = isOpen && !isOther;

  useDidUpdateEffect(() => {
    if (value === '' && selectedItems.length === 0) {
      return;
    }

    if (!isEqual(value, selectedItems)) {
      if (isOther) {
        setSelectedItems(['Other']);
      }
      onChange(selectedItems, name);
    }
  }, [selectedItems, value]);

  const handleRemoveItem = (
    event: MouseEvent<HTMLSpanElement> | KeyboardEvent<HTMLSpanElement>,
    selectedItem: string,
  ) => {
    event.stopPropagation();
    handleRemoveSelectedItem(selectedItem);
  };

  return (
    <div className={clsx('relative', dropdownWrapperClassName)}>
      <div
        data-testid={'multiselect-dropdown'}
        className={clsx(
          inputClasses({
            isDisabled,
            isIncomplete,
            size,
          }),
          multiSelectDropdownClasses,
        )}
        {...getToggleButtonProps({ disabled: isDisabled })}
        tabIndex={0}
      >
        <div className={clsx('multi-select-wrapper flex', dropdownInnerWrapperClassName)} ref={inputRef}>
          {selectedItems.map((selectedItem, index) => (
            <div className={clsx(selectedItemStyle, selectedItemClassName)} key={`selected-item-${selectedItem}`}>
              <span
                {...getSelectedItemProps({ selectedItem, index })}
                title={selectedItem}
                className={clsx(selectedItemLabelClassName)}
              >
                {formatItemLabel(selectedItem, selectLabelMaxLength)}
              </span>
              <span
                data-testid={`Remove-${selectedItem}`}
                className="cursor-pointer rounded-full px-1 hover:bg-info-400"
                onClick={(ev) => handleRemoveItem(ev, selectedItem)}
                onKeyDown={(ev) => (isEnterKey(ev) ? handleRemoveItem(ev, selectedItem) : undefined)}
              >
                &#10005;
              </span>
            </div>
          ))}
          {selectedItems.length === 0 && (
            <label
              className="cursor-pointer text-sm font-normal text-info-400"
              onClick={toggleMenu}
              onKeyDown={(ev) => (isEnterKey(ev) ? toggleMenu() : undefined)}
              {...getLabelProps()}
            >
              {placeholder}
            </label>
          )}
        </div>
        <div className={clsx('flex', isOther && 'cursor-not-allowed')}>
          <input className="hidden" {...getInputProps(getDropdownProps({ preventKeyAction: isDropdownOpen }))} />
          {!isDropdownOpen && <Icon name="chevron-down" className="ml-2 self-center fill-current text-info-800" />}
          {isDropdownOpen && <Icon name="chevron-up" className="ml-2 self-center fill-current text-primary-700" />}
        </div>
      </div>
      <div {...getMenuProps()}>
        {isDropdownOpen && (
          <InputSelectMenuContainer
            anchorRef={inputRef}
            className={clsx(inputSelectStyles.menuOuter, menuOuterClassName)}
          >
            <div>
              {isSearchable && (
                <input autoFocus className={inputSelectStyles.input} placeholder="Search..." {...getInputProps()} />
              )}
              <ul
                className={inputSelectStyles.menuInner}
                style={{
                  maxHeight: MAX_DROPDOWN_HEIGHT_PX,
                }}
              >
                {getFilteredItems(options).map((item, index) => (
                  <li
                    data-testid={item}
                    className={clsx(inputSelectStyles.menuItem, index === highlightedIndex && 'bg-info-100')}
                    key={`${item}`}
                    {...getItemProps({ item, index })}
                  >
                    <span className="truncate">{item}</span>
                  </li>
                ))}
                {isFull && <li className={clsx(inputSelectStyles.menuItem, 'italic opacity-60')}>No options left</li>}
              </ul>
            </div>
          </InputSelectMenuContainer>
        )}
      </div>
    </div>
  );
};
