import React, { useState } from 'react';
import type { Action, Column, Filter, Tag } from '@root/@types/types';
import { formatDate } from '@root/helpers';
import withMenuLayout from '@root/src/layout/withMenuLayout';
import { useEffectOnce, useModal } from '@src/hooks';
import { fetchTags } from '@src/queries';

import { MODALS } from '../../constants';

import { Table } from './components';
import { useDataQuery } from './hooks';

const initialFilters: Filter[] = [];

const initialColumns: Column[] = [
  {
    label: 'Label',
    key: 'label',
    sortable: true,
    normalizer: (fieldValue = '') => fieldValue.toLowerCase(),
  },
  {
    label: 'Created at',
    key: 'created_at',
    sortable: true,
    formatter: (value: string) => (value ? formatDate(value, 'DD/MM/YYYY HH:mm') : value),
    headingCellClassName: 'w-36',
  },
  {
    label: 'Edit',
    key: 'edit',
    type: 'link',
    href: '#',
  },
];

export const Tags: React.FC = () => {
  const { showModal } = useModal();
  const [columns, setColumns] = useState(initialColumns);
  const { sortOrder, data, status, filters, toggleSortOrderDirection, setShouldFetchData, onFilterChange } =
    useDataQuery('tags', initialFilters, fetchTags);

  const handleEditTagClick = (tag: Tag) => () => showModal(MODALS.ADMIN_TAG, { tag, setShouldFetchData, isAdd: false });

  useEffectOnce(() => {
    const clonedColumns = [...columns];
    clonedColumns[clonedColumns.findIndex((column: Column) => column.key === 'edit')].onClick = handleEditTagClick;
    setColumns(clonedColumns);
  });

  const actions: Action[] = [
    {
      key: 'add_tag',
      label: 'New Tag',
      onClick: () => {
        showModal(MODALS.ADMIN_TAG, { setShouldFetchData, isAdd: true });
      },
    },
  ];

  return (
    <Table
      actions={actions}
      columns={columns}
      filters={filters}
      onFilterChange={onFilterChange}
      sortOrder={sortOrder}
      toggleSortOrderDirection={toggleSortOrderDirection}
      filteredData={data}
      status={status}
    />
  );
};

export const UserTagsPage = withMenuLayout(Tags);
