import { useState } from 'react';

export type OnSaveFn = (field: string, value?: string | number[]) => Promise<void>;

interface UseEditableFieldArgs {
  defaultValue?: string | number[];
  name: string;
  onSave: OnSaveFn;
}

export const useEditableField = ({ defaultValue, onSave, name }: UseEditableFieldArgs) => {
  const [state, setState] = useState({ value: defaultValue, isEditing: false, isUpdating: false });

  const handleUpdate = async () => {
    setState((prev) => ({ ...prev, isUpdating: true }));
    try {
      await onSave(name, state.value);
    } finally {
      setState((prev) => ({ ...prev, isUpdating: false, isEditing: false }));
    }
  };

  const handleClick = () => {
    setState((prev) => ({ ...prev, isEditing: true }));
  };

  const handleCancel = () => {
    setState((prev) => ({ ...prev, isEditing: false, value: defaultValue }));
  };

  const handleChange = (value?: string | number[]) => {
    setState((prev) => ({ ...prev, value }));
  };

  return {
    onCancel: handleCancel,
    onChange: handleChange,
    onClick: handleClick,
    onUpdate: handleUpdate,
    state,
  };
};
