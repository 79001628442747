import { extractRepeatersFromContent } from '@ContractBuilder/modules/block-edit/utils/extract-repeaters-from-content';
import type { BlockVariation } from '@root/@types/database';
import { isEmpty } from 'lodash-es';

import type { BlockEditFormState } from '../types';

import { updateDatapointsFromContent } from './update-datapoints-from-content';

export const updateDataPointsInForm = (
  formValues: BlockEditFormState,
  previousFormValues?: BlockEditFormState,
): BlockEditFormState => {
  if (!previousFormValues) {
    return formValues;
  }

  const isSingleBlock = isEmpty(formValues.variations);
  const isVariations = !isEmpty(formValues.variations);
  const wasSingleBlock = isEmpty(previousFormValues.variations);
  const wasVariations = !isEmpty(previousFormValues.variations);
  const isVariationsInit = wasSingleBlock && isVariations;
  const isRevertingToBlock = wasVariations && isSingleBlock;

  let updatedFormValues: BlockEditFormState = structuredClone(formValues);

  switch (true) {
    case isVariationsInit:
      updatedFormValues.linkedDatapoints = [];
      break;
    case isRevertingToBlock:
      updatedFormValues.variations = [];
      break;
    default:
      break;
  }

  switch (true) {
    case isSingleBlock: {
      const updatedLinkedDatapoints = updateDatapointsFromContent(
        formValues.content ?? '',
        formValues.linkedDatapoints,
      );

      const repeaterIds = extractRepeatersFromContent(formValues.content);
      updatedFormValues.repeaterIds = repeaterIds;

      if (updatedLinkedDatapoints) {
        updatedFormValues.linkedDatapoints = updatedLinkedDatapoints;
      }
      break;
    }
    case isVariations: {
      const changedVariations = formValues.variations!.map((v: BlockVariation) => {
        const linkedDatapoints = updateDatapointsFromContent(v.content, v.linkedDatapoints);
        const variationRepeaterIds = extractRepeatersFromContent(v.content);
        return {
          ...v,
          linkedDatapoints,
          repeaterIds: variationRepeaterIds,
          content: v.content ?? '',
        };
      });
      updatedFormValues.variations = changedVariations;
      break;
    }

    default:
      break;
  }

  return updatedFormValues;
};
