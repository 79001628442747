import axios from 'axios';

import { getRequestAuth } from '../helpers/getRequestAuth';

const MRC_DOCUMENT_UPLOAD_URI = 'data-extraction';
const SUBMISSIONS_URI = 'submissions-spa-al';

export const updateMRCAdapters = async (submissionId: string, extractionId: string, data: any): Promise<string> => {
  const auth = await getRequestAuth();
  const url = [
    import.meta.env.VITE_API_URL,
    SUBMISSIONS_URI,
    submissionId,
    MRC_DOCUMENT_UPLOAD_URI,
    extractionId,
    'adapters',
  ]
    .filter(Boolean)
    .join('/');

  const response = await axios({
    method: 'patch',
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
    data,
  });

  return response.data;
};
