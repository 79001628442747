import type { EndorsementPremiumChange } from '@ContractBuilder/components/Modal/constants';
import type { ResourceBlock } from '@root/@types/base';
import type {
  AnyCase,
  DataExtractionData,
  DynamoDBResourceComment,
  DynamoDBResourceSection,
  EntityDocumentType,
  PdfDocument,
  ResourceName,
  RootResourceStatus,
  StandardisedSectionName,
  User,
} from '@root/@types/types';

export interface LibraryBlockType extends ResourceBlock {
  class_of_business?: string;
  selected?: boolean;
  standardised_section_name: StandardisedSectionName;
  variationsCount: number;
}

export interface Attachment {
  id: string;
  name: string;
  fileName: string;
  size: number;
  path: string;
  type: 'pre' | 'post' | string;
}

export enum AuditLogChangeType {
  FORM_UPDATED = 'FORM_UPDATED',
  STATUS_UPDATED = 'STATUS_UPDATED',
  BLOCK_UPDATED = 'BLOCK_UPDATED',
  BLOCK_CREATED = 'BLOCK_CREATED',
  BLOCK_DELETED = 'BLOCK_DELETED',
  SUBMISSION_CREATED = 'SUBMISSION_CREATED',
  COMMENTS_THREAD_RESOLVED = 'COMMENTS_THREAD_RESOLVED',
  COMMENT_ADDED = 'COMMENT_ADDED',
}

interface CommentAddedAuditLogInfo {
  isResolved?: boolean;
  threadId?: string;
  content: string;
}

export type AuditLogInfo =
  | { info: CommentAddedAuditLogInfo; type: AuditLogChangeType.COMMENT_ADDED }
  | { type: Exclude<AuditLogChangeType, AuditLogChangeType.COMMENT_ADDED> };

export type AuditLogItem = {
  created_at: string;
  block_id: string | null;
  block_name: string | null;
  updated_data_items: string[] | null;
  updated_status?: { from: StatusType; to: StatusType };
  user?: User;
} & AuditLogInfo;

export type RevisionHistoryItem = {
  revision_id: string;
  revision_name: string;
  revision_created_at: string;
  updated_at: string;
  last_updated_by: User;
};

export interface Section extends DynamoDBResourceSection {
  blocks: ResourceBlock[];
}

export type EntityData = {
  ref?: string;
  schema_id?: string;
  attachments?: Attachment[];
  attachments_layout?: string[];
  comments: DynamoDBResourceComment[];
  changed_blocks?: ResourceBlock[];
  dataExtractions?: DataExtractionData[];
  audit_log: AuditLogItem[];
  branding?: string;
  class_of_business: string;
  created_at: string;
  data_items: Record<string, Record<string, string | number> | any>;
  /** @deprecated Attr `document_name` is deprecated - use `documents` instead */
  document_name?: string;
  document_type: EntityDocumentType;
  document_version?: number; // pdf documents version
  documents?: PdfDocument[]; // pdf documents
  id: string;
  name: string;
  pk: string;
  sk: string;
  umr: string;
  updated_at?: string;
  version?: number; // pdf doc version
  tags?: string[];
  tenant_id?: string;
  status?: StatusType;
  sections: Section[];
  sourceResourceName?: ResourceName;
  sourceResourceId?: string;
  sourceResourceDisplayName?: string;
  layout: string[];
  last_updated_by?: User;
  parentId?: string;
  revision_id?: string;
  revision_name?: string;
  revision_created_at?: string;
  revision_history?: RevisionHistoryItem[];

  teams?: number[];
};

export interface EndorsementData extends EntityData {
  additional_premium?: { amount: number; currency: string };
  changed_blocks?: ResourceBlock[];
  effective_date?: string;
  effective_date_details?: string;
  endorsement_amendment_text?: string;
  endorsement_summary?: string;
  expiry_date?: string;
  parentId: string;
  premium_change?: EndorsementPremiumChange;
  reason?: string;
  reference?: string;
  type?: string;
  // @TODO: [CB-2084](https://artificiallabs.atlassian.net/browse/CB-2084)
  // name?: string;
}

export const isEndorsementData = (entity: EntityData | EndorsementData): entity is EndorsementData =>
  entity.pk.startsWith('submissions#') && entity.sk.startsWith('endorsements#');

export type SubmissionPatchResponse =
  | {
      message: 'Server Error';
    }
  | { message: 'success'; blockId?: string };

export type StatusType = RootResourceStatus;

export type PublishingOptionType = {
  id: StatusType;
  title: AnyCase<StatusType>;
  description: string;
};

export type UsedInTemplates = {
  id: string;
  name: string;
}[];
