import React from 'react';
import { InputField, type InputFieldProps } from '@components/InputField';
import { InputMultiSelectInner } from '@components/InputMultiSelect/InputMultiSelectInner';
import type { UIOnChangeFn } from '@root/@types/types';

import InputReadOnly from '../InputReadOnly';

import '../InputMultiSelect.css';

const SELECT_LABEL_MAX_LENGTH = 20;

export interface InputMultiSelectProps extends InputFieldProps {
  isReadOnly?: boolean;
  isSearchable?: boolean;
  options: string[];
  value?: string | string[];
  'aria-labelledby'?: string;
  formatter?: (value: string) => string;
  itemFormatter?: (value: string) => string;
  onChange: UIOnChangeFn<string[]>;
  onSelect: (args: unknown) => void;
  initialSelectedItem?: string[];
  placeholder?: string;
  selectLabelMaxLength?: number;
  shouldHideTitle?: boolean;
  menuOuterClassName?: string;
  selectedItemClassName?: string;
  selectedItemLabelClassName?: string;
  dropdownWrapperClassName?: string;
  dropdownInnerWrapperClassName?: string;
  multiSelectDropdownClasses?: string;
}

const InputMultiSelect: React.FC<InputMultiSelectProps> = ({
  className,
  menuOuterClassName,
  selectedItemClassName,
  selectedItemLabelClassName,
  dropdownWrapperClassName,
  multiSelectDropdownClasses,
  dropdownInnerWrapperClassName,
  descriptionText,
  errors,
  isReadOnly,
  isSearchable = true,
  labelText,
  helperText,
  shortName,
  placeholder = 'Select',
  onChange,
  onSelect: _onSelect,
  options = [],
  name = 'multiselect',
  size = 'md',
  value = [],
  selectLabelMaxLength = SELECT_LABEL_MAX_LENGTH,
  shouldHideTitle,
  isIncomplete,
  ...props
}) => {
  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      labelText={shouldHideTitle ? '' : labelText}
      helperText={helperText}
      shortName={shortName}
      name={name}
      errors={errors}
      {...props}
    >
      {isReadOnly && (
        <InputReadOnly
          {...props}
          aria-describedby={descriptionText ? `${name}Description` : undefined}
          name={name}
          value={Array.isArray(value) ? value.join(', ') : value}
        />
      )}

      {!isReadOnly && (
        <InputMultiSelectInner
          isIncomplete={isIncomplete}
          isDisabled={props.isDisabled}
          isSearchable={isSearchable}
          name={name}
          onChange={onChange}
          options={options}
          placeholder={placeholder}
          selectLabelMaxLength={selectLabelMaxLength}
          size={size}
          value={value}
          menuOuterClassName={menuOuterClassName}
          selectedItemClassName={selectedItemClassName}
          selectedItemLabelClassName={selectedItemLabelClassName}
          dropdownWrapperClassName={dropdownWrapperClassName}
          multiSelectDropdownClasses={multiSelectDropdownClasses}
          dropdownInnerWrapperClassName={dropdownInnerWrapperClassName}
        />
      )}
    </InputField>
  );
};

export default InputMultiSelect;
