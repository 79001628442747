import type { UpdateEndorsementDataPayload } from '@root/functions/validation';

import { EndorsementPremiumChange } from '../constants';

export type FormState = Partial<UpdateEndorsementDataPayload>;

export const isConditionalPremiumOption = (premium_change?: EndorsementPremiumChange) =>
  Boolean(
    premium_change && [EndorsementPremiumChange.ADDITION, EndorsementPremiumChange.RETURN].includes(premium_change),
  );

export const isFormValid = (state: FormState): state is UpdateEndorsementDataPayload => {
  return (
    Boolean(
      state.type &&
        state.effective_date &&
        state.reason &&
        state.reference &&
        state.expiry_date &&
        state.premium_change,
    ) &&
    Boolean(
      isConditionalPremiumOption(state.premium_change)
        ? state.additional_premium && state.additional_premium.amount && state.additional_premium.currency
        : true,
    )
  );
};
