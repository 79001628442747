import { getRequestAuth } from '@helpers/getRequestAuth';
import axios from 'axios';

const SUBMISSIONS_URI = 'submissions-spa-al';
const MRC_DOCUMENT_UPLOAD_URI = 'data-extraction';

interface MrcExtractionDocumentPresignedUrlResponse {
  data: {
    id: string;
    url: string;
    fields: Record<string, unknown>;
  };
}

export const getMrcExtractionDocumentPresignedUrl = async (submissionId: string, fileName: string) => {
  const auth = await getRequestAuth();
  const url = [import.meta.env.VITE_API_URL, SUBMISSIONS_URI, submissionId, MRC_DOCUMENT_UPLOAD_URI]
    .filter(Boolean)
    .join('/');

  const response = await axios<MrcExtractionDocumentPresignedUrlResponse>({
    method: 'post',
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
    data: {
      file: fileName,
    },
  });

  return response;
};
