import { InsertMenu } from '@WysiwygEditor/components/InsertMenu';

import type { CustomOptionProps } from '../types';

export const getDatapointControls = (editor: any): CustomOptionProps => {
  return {
    custom: () => <InsertMenu editor={editor} key="insert-menu" />,
    key: 'insert-menu',
  };
};
