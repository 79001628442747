import type { FC } from 'react';
import { useBlockEdit, useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { useEntityStore } from '@ContractBuilder/store';

import { BlockConfigurationPanel } from '../views/BlockConfigurationPanel';

interface BlockConfigurationPanelControllerProps {
  onCancel: () => void;
  onSubmit: () => void | Promise<void>;
  shouldShowSections?: boolean;
}

export const BlockConfigurationPanelController: FC<BlockConfigurationPanelControllerProps> = ({
  onCancel,
  onSubmit,
  shouldShowSections = true,
}) => {
  const { formValues: state } = useBlockEditFormStore(({ formValues }) => ({
    formValues,
  }));
  const { onUpdateRequiredDatapoint } = useBlockEdit();
  const { submission } = useEntityStore(({ submission }) => ({
    submission,
  }));

  const sectionOptions = submission?.sections.map(({ id, label }) => ({ name: label, value: id }));
  const currentSection = sectionOptions?.find((item) => item.value === state?.section_id);

  if (shouldShowSections && (!currentSection || !sectionOptions)) {
    return null;
  }

  const handleSubmit = async () => {
    await onSubmit();
  };

  return (
    <BlockConfigurationPanel
      onCancel={onCancel}
      onRequiredDatapointChange={onUpdateRequiredDatapoint}
      onSubmit={handleSubmit}
      sectionOptions={sectionOptions}
    />
  );
};
