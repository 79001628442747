import type { SchemaValidation } from '@ContractBuilder/store';
import type { FormError } from '@root/@types/types';
import { every, get, isObject, map, omit } from 'lodash-es';

import type { SchemaTreeToFlatListResult } from './schemaTreeToFlatList';
import { getSectionsErrors } from './section-errors-utils';

type FormValues = Record<string, any>;

export const getAllCollapsibleSectionsKeys = (schemaTreeFlat: SchemaTreeToFlatListResult[]) => {
  const components = ['ToggleableSectionRepeater', 'Section', 'SectionRepeater'];
  return schemaTreeFlat.reduce((acc: string[], item: SchemaTreeToFlatListResult) => {
    if (components.includes(get(item, 'ui:component', ''))) {
      acc.push(item.id);
    }
    return acc;
  }, []);
};

const isEmptyDeep = (obj: unknown): boolean => {
  if (!obj) {
    return true;
  }

  if (isObject(obj)) {
    if (Object.keys(obj).length === 0) {
      return true;
    }

    return every(map(obj, (v) => isEmptyDeep(v)));
  }

  return false;
};

export const getSectionValidationState = (section: string, formValues: FormValues, errors?: FormError[] | null) => {
  const sectionErrors = getSectionsErrors(section, errors);
  const hasErrors = sectionErrors.length > 0;
  const hasValues = !isEmptyDeep(get(formValues, section));

  return {
    hasErrors,
    hasValues,
    errors: sectionErrors,
  };
};

export const createSchemaValidation = (
  schemaTreeFlat: SchemaTreeToFlatListResult[] | null,
  formValues: FormValues,
  errors: FormError[] | null,
) => {
  if (!schemaTreeFlat) {
    return {};
  }

  const minimalErrors = errors?.map((error) => omit(error, ['schema', 'parentSchema']));

  const keys = getAllCollapsibleSectionsKeys(schemaTreeFlat);

  return keys.reduce((acc: SchemaValidation, key: string) => {
    acc[key] = getSectionValidationState(key, formValues, minimalErrors);
    return acc;
  }, {});
};
