import { getRequestAuth } from '@helpers/getRequestAuth';
import type { TransformedCognitoUser } from '@root/@types/cognito';
import axios from 'axios';

export const fetchUsers = async (authToken: string): Promise<TransformedCognitoUser[]> => {
  const auth = await getRequestAuth(authToken);

  const response = await axios({
    method: 'get',
    url: `${import.meta.env.VITE_API_URL}/users-spa-al`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
  });

  return response.data;
};
