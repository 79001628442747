import { useEntityStore } from '@ContractBuilder/store';

export const useBlockActions = () => {
  const {
    addBlockFromLibrary,
    createBlock,
    createBlockInLibrary,
    deleteBlock,
    replaceBlockForBlockInLibrary,
    updateBlock,
    updateBlockInLibrary,
  } = useEntityStore(
    ({
      addBlockFromLibrary,
      createBlock,
      createBlockInLibrary,
      deleteBlock,
      replaceBlockForBlockInLibrary,
      updateBlock,
      updateBlockInLibrary,
    }) => ({
      addBlockFromLibrary,
      createBlock,
      createBlockInLibrary,
      deleteBlock,
      replaceBlockForBlockInLibrary,
      updateBlock,
      updateBlockInLibrary,
    }),
  );

  return {
    addBlockFromLibrary,
    createBlock,
    createBlockInLibrary,
    deleteBlock,
    replaceBlockForBlockInLibrary,
    updateBlock,
    updateBlockInLibrary,
  };
};
