import { type FC } from 'react';
import { BlockBody } from '@ContractBuilder/modules/block/components/BlockBody';
import { useBlockFlags } from '@ContractBuilder/modules/block/context/context';
import type { EntityData } from '@ContractBuilder/types';
import type { BlockMentionValues } from '@ContractBuilder/utils/get-block-mention-values';
import type { ClauseListKey } from '@ContractBuilder/utils/get-clauses-as-mentions';
import { getClauseTypeForClauseListKey, keyMap } from '@ContractBuilder/utils/get-clauses-as-mentions';
import { sanitizeHtml } from '@helpers/sanitizeHtml';
import type { ResourceBlock } from '@root/@types/base';
import clsx from 'clsx';
import { isString, mapValues } from 'lodash-es';

import { blockContentClasses, blockTitleClasses } from '../constants/classes';
import { getBlockDeletedCopy } from '../constants/constants';
import { getNewText, getOldText, getParsedOutput } from '../utils/get-block-diff';

import { BlockContentDeleted } from './BlockContentDeleted';

interface BlockContentDiffProps {
  block: ResourceBlock;
  endorsement?: EntityData;
  endorsementParent?: EntityData;
  endorsementParentMentionValues?: BlockMentionValues;
  isPDFRender?: boolean;
  parentBlock?: ResourceBlock;
  showEndorsementDiff?: boolean;
}

const CLAUSE_KEYS = Object.values(keyMap) as ClauseListKey[];

const replaceEmptyClausesFormatting = (mentionValues: Record<string, string | number> = {}) => {
  return mapValues(mentionValues, (value, key) => {
    /** Remove `No X specified` clauses formatting for mention values for a more accurate diff **/
    if (CLAUSE_KEYS.includes(key as ClauseListKey) && isString(value)) {
      const type = getClauseTypeForClauseListKey(key as ClauseListKey);
      if (type && value === `No ${type.toLocaleLowerCase()} specified`) {
        return '<ul></ul>';
      }
    }

    return value;
  }) as BlockMentionValues;
};

export const BlockContentDiff: FC<BlockContentDiffProps> = ({
  block,
  endorsement,
  endorsementParent,
  endorsementParentMentionValues,
  isPDFRender,
  parentBlock,
  showEndorsementDiff,
}) => {
  const {
    isHighlighted,
    details: { isVisible, isEditing },
    mentionValues,
  } = useBlockFlags();
  // TODO:refactor usage of deprecated order
  const { name, type, id, order, title } = block;

  if (block.isDeleted) {
    return <BlockContentDeleted block={block} isHighlighted={isHighlighted} isPdfRender={isPDFRender} />;
  }

  const mentionValuesForDiff = replaceEmptyClausesFormatting(mentionValues);
  const parentMentionValuesForDiff = replaceEmptyClausesFormatting(endorsementParentMentionValues);

  const oldText = getOldText(block, parentBlock, parentMentionValuesForDiff, mentionValuesForDiff);
  const newText = getNewText(block, parentMentionValuesForDiff, mentionValuesForDiff);

  const parsedOutput = block.isDeleted
    ? getBlockDeletedCopy(title ?? name)
    : getParsedOutput(oldText, newText, endorsementParent, endorsement);

  const isTwoColLayout = ['mrc-heading'].includes(type);

  return (
    <BlockBody
      id={id}
      name={name}
      order={order}
      isEditing={isEditing}
      isInCreateMode={false}
      isHighlighted={isHighlighted}
      isLockedFromEditing
      isPDFRender={isPDFRender}
      isVisible={isVisible}
      showEndorsementDiff={showEndorsementDiff}
    >
      {isTwoColLayout && !block.isDeleted && (
        <div
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(title ?? name ?? '') }}
          className={blockTitleClasses({ type })}
        />
      )}
      <div className={clsx(blockContentClasses({ type }))}>
        <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(parsedOutput) }} />
      </div>
    </BlockBody>
  );
};
