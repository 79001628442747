import type { EntityData } from '@ContractBuilder/types';
import type { DocumentContext } from '@ContractBuilder/utils';
import { canChange } from '@helpers/canChange';
import type { ResourceBlock } from '@root/@types/base';
import type { AmplifyUser } from '@root/@types/types';

import { crossCheckBlockUserCtx } from './cross-check-block-user-ctx';
import { blockRulesIsVisible } from '.';

export type BlockRulesIsEditableArgs = {
  block: ResourceBlock;
  context: DocumentContext;
  disableControls: boolean;
  isRevisionHistory: boolean;
  showEndorsementDiff?: boolean;
  status: EntityData['status'];
  user: AmplifyUser | null;
};

export const blockRulesIsEditable = ({
  block,
  context,
  disableControls,
  isRevisionHistory,
  showEndorsementDiff,
  status,
  user,
}: BlockRulesIsEditableArgs) => {
  const documentConditionsAllowChange =
    canChange(status) &&
    !showEndorsementDiff &&
    !isRevisionHistory &&
    !disableControls &&
    blockRulesIsVisible(block, context);

  const { canBeEditedWithCurrentPermissions } = crossCheckBlockUserCtx(block, context, user);

  if (!documentConditionsAllowChange || !canBeEditedWithCurrentPermissions) {
    return false;
  }

  return canBeEditedWithCurrentPermissions;
};
