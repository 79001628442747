import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import Modal from '@components/Modal';
import { BlockEditModalLoadingView } from '@ContractBuilder/modules/block-edit-modal/view/BlockEditModalLoadingView';
import { useOnBlockEditSubmit } from '@ContractBuilder/modules/inline-block-edit';
import { useEntityStore, useSchemaStore } from '@ContractBuilder/store';
import type { UsedInTemplates } from '@ContractBuilder/types';
import { useEffectOnce } from '@src/hooks';
import { fetchBlock, fetchTemplates } from '@src/queries';
import clsx from 'clsx';

import { useBlockEdit, useBlockEditFormStore } from '../../block-edit';
import { VariationsTabsProvider } from '../../variations-tabs';
import BlockEditModalView from '../view/BlockEditModalView';

export interface BlockEditModalControllerProps {
  blockId?: string;
  handleClose: () => void;
  onAfterSubmit?: () => void;
  open?: boolean;
}

const getUsedInPublishedTemplates = async (usedInTemplates: UsedInTemplates) => {
  const templates = await fetchTemplates('');
  return usedInTemplates
    .filter((i) => templates.find((template) => template.id === i.id && template.status === 'PUBLISHED') !== undefined)
    .map(({ id, name }) => ({ id, name }));
};

export const BlockEditModalController: FC<BlockEditModalControllerProps> = ({
  blockId,
  handleClose,
  onAfterSubmit,
  open,
}) => {
  const mode = blockId ? 'edit' : 'create';

  const {
    formValues: state,
    hasChanges,
    reset,
    setFormValues,
  } = useBlockEditFormStore(({ formValues, hasChanges, reset, setFormValues }) => ({
    formValues,
    hasChanges,
    reset,
    setFormValues,
  }));
  const { updateSchemaStore } = useSchemaStore(({ updateSchemaStore }) => ({ updateSchemaStore }));
  const { onCancel, setOnCancelCallback } = useBlockEdit();
  const { onSubmit } = useOnBlockEditSubmit();
  const { archiveBlockInLibrary, restoreBlockInLibrary } = useEntityStore(
    ({ archiveBlockInLibrary, restoreBlockInLibrary }) => ({ archiveBlockInLibrary, restoreBlockInLibrary }),
  );
  const [isBlockUsedWithinWarningOpen, setIsBlockUsedWithinWarningOpen] = useState(false);
  const [isBlockUsedWithinPublishedWarningOpen, setIsBlockUsedWithinPublishedWarningOpen] = useState(false);
  const [usedInPublishedTemplates, setUsedInPublishedTemplates] = useState<UsedInTemplates>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleCancelInBlockUsedWithinModal = () => {
    setIsBlockUsedWithinWarningOpen(false);
  };
  const handleCancelInBlockUsedWithinPublishedModal = () => {
    setIsBlockUsedWithinPublishedWarningOpen(false);
  };

  useEffect(() => {
    setOnCancelCallback({ fn: handleClose });
    // eslint-disable-next-line -- We only want to react to `open` changes
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      if (blockId) {
        setIsLoading(true);
        const { data } = await fetchBlock(blockId);
        setFormValues(data);
        setIsLoading(false);
      }
    }

    if (open) {
      fetchData();
    }
    // eslint-disable-next-line -- We only want to react to swapping blocks for edit mode
  }, [open]);

  useEffectOnce(() => {
    updateSchemaStore({});
  });

  const isBlockUsedWithinTemplates = (state?.usedInTemplates?.length ?? 0) > 0;

  const onBlockRestoreOrArchive = async () => {
    const blockId = state?.id;

    if (!blockId) {
      return;
    }

    if (state?.deleted_at) {
      await restoreBlockInLibrary(blockId);
    } else {
      if (isBlockUsedWithinTemplates && !isBlockUsedWithinWarningOpen) {
        const usedInPublishedTemplates = await getUsedInPublishedTemplates(
          (state?.usedInTemplates ?? []) as UsedInTemplates,
        );
        setUsedInPublishedTemplates(usedInPublishedTemplates);

        if (usedInPublishedTemplates.length > 0 && !isBlockUsedWithinPublishedWarningOpen) {
          setIsBlockUsedWithinPublishedWarningOpen(true);
          return;
        }

        setIsBlockUsedWithinWarningOpen(true);
        return;
      }
      await archiveBlockInLibrary(blockId);
    }

    if (usedInPublishedTemplates.length > 0) {
      setIsBlockUsedWithinPublishedWarningOpen(false);
    }
    if (isBlockUsedWithinTemplates) {
      setIsBlockUsedWithinWarningOpen(false);
    }

    onClose();
    onAfterSubmit?.();
  };

  const onClose = useCallback(() => {
    onCancel(!hasChanges);
    reset();

    if (!hasChanges) {
      handleClose();
    }
    // eslint-disable-next-line -- Function dependencies won't change
  }, [hasChanges]);

  const handleSubmit = useCallback(async () => {
    const formValues = useBlockEditFormStore.getState().formValues;

    if (formValues) {
      const hasCompleted = await onSubmit(formValues);

      if (hasCompleted) {
        handleClose();
        onAfterSubmit?.();
        reset();
      }
    }
    // eslint-disable-next-line -- Function dependencies won't change
  }, []);

  const title = {
    edit: state?.name ? `Edit "${state?.name}" block` : 'Edit block',
    create: 'Create new block',
  }[mode];

  return (
    <Modal
      className={clsx(`!p-0`)}
      modalClassName="z-[99]"
      data-testid="block-edit-modal"
      open={!!open}
      onClose={onClose}
      title={isLoading ? <div className="h-7 w-[30%] animate-pulse rounded bg-slate-200"></div> : <p>{title}</p>}
      titleClassName="!text-2xl !font-bold !text-info-900 !pb-0 !pt-2 !px-4"
    >
      <VariationsTabsProvider>
        {isLoading ? (
          <BlockEditModalLoadingView />
        ) : (
          <BlockEditModalView
            isBlockUsedWithinWarningOpen={isBlockUsedWithinWarningOpen}
            isBlockUsedWithinPublishedWarningOpen={isBlockUsedWithinPublishedWarningOpen}
            mode={mode}
            onBlockRestoreOrArchive={onBlockRestoreOrArchive}
            onCancel={onClose}
            onSubmit={handleSubmit}
            handleCancelInBlockUsedWithinModal={handleCancelInBlockUsedWithinModal}
            handleCancelInBlockUsedWithinPublishedModal={handleCancelInBlockUsedWithinPublishedModal}
            usedInPublishedTemplates={usedInPublishedTemplates}
          />
        )}
      </VariationsTabsProvider>
    </Modal>
  );
};
