import React from 'react';
import ItemTeams from '@pages/User/components/ItemTeams';
import { accumulateFilterOptions, insertNewOptions } from '@pages/User/components/prepareDropdownOptions';
import StatusBadge from '@pages/User/components/StatusBadge';
import type { Action, Column, Filter } from '@root/@types/types';
import { SUBMISSION_STATUSES } from '@root/@types/types';
import { tenantConfig } from '@root/config/tenants';
import { formatDate } from '@root/helpers';
import { ALL_CLASSES_OF_BUSINESS } from '@root/helpers/constants';
import withMenuLayout from '@root/src/layout/withMenuLayout';
import { useModal } from '@src/hooks';
import { fetchSubmissions } from '@src/queries';

import { MODALS } from '../../constants';

import ItemTags from './components/ItemTags';
import { Table } from './components';
import { useDataQuery } from './hooks';
const initialFilters: Filter[] = [
  {
    label: 'Submission ID',
    key: 'ref',
    type: 'dropdown',
    placeholder: 'All IDs',
    options: [],
    selected: [],
  },
  {
    label: 'Submission name',
    key: 'name',
    type: 'dropdown',
    placeholder: 'All submissions',
    options: [],
    selected: [],
  },
  {
    label: 'UMR',
    key: 'umr',
    type: 'dropdown',
    placeholder: 'All UMRs',
    options: [],
    selected: [],
  },
  {
    label: 'Status',
    key: 'status',
    type: 'dropdown',
    placeholder: 'All statuses',
    options: SUBMISSION_STATUSES,
    selected: SUBMISSION_STATUSES.filter((status) => status !== 'ARCHIVED'),
  },
  {
    label: 'Tags',
    key: 'tags',
    type: 'dropdown',
    placeholder: 'All tags',
    options: [],
    selected: [],
  },
  {
    label: 'Class of Business',
    key: 'class_of_business',
    type: 'dropdown',
    placeholder: 'All classes',
    options: ALL_CLASSES_OF_BUSINESS,
    selected: [],
  },
  {
    label: 'Created by',
    key: 'created_by',
    type: 'dropdown',
    placeholder: 'All created by',
    options: [],
    selected: [],
  },
  {
    label: 'Team',
    key: 'teams',
    type: 'dropdown',
    placeholder: 'All teams',
    options: (tenantConfig.teams ?? []).map((i) => i.name),
    selected: [],
  },
  {
    label: 'Template',
    key: 'sourceResourceDisplayName',
    type: 'dropdown',
    placeholder: 'All templates',
    options: [],
    selected: [],
  },
  {
    label: 'Branding',
    key: 'branding',
    type: 'dropdown',
    placeholder: 'All brands',
    options: [],
    selected: [],
  },
];

const columns: Column[] = [
  {
    label: 'Submission ID',
    key: 'ref',
    sortable: true,
    headingCellClassName: '!w-36',
    dataCellClassName: '!w-36',
    normalizer: (fieldValue) => Number(fieldValue.replace('ART-S-', '')),
  },
  {
    label: 'Name',
    key: 'name',
    sortable: true,
  },
  {
    label: 'UMR',
    key: 'umr',
    sortable: true,
  },
  {
    label: 'Team',
    key: 'teams',
    headingLinkClassName: 'pl-0',
    dataCellClassName: 'truncate whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-info-900 sm:pl-6',
    render: (item: any) => <ItemTeams teams={item.teams ?? []} />,
  },
  {
    label: 'Status',
    key: 'status',
    sortable: true,
    headingCellClassName: '!w-24',
    headingLinkClassName: '!pl-0',
    render: (item: any) => <StatusBadge status={item.status} />,
  },
  {
    label: 'Tags',
    key: 'tags',
    headingLinkClassName: '!pl-0',
    render: (item: any, tags, setIsLoading) => (
      <ItemTags item={item} tags={tags ?? []} setIsLoading={setIsLoading} queryKey="submissions" />
    ),
  },
  {
    label: 'Updated at',
    key: 'updated_at',
    sortable: true,
    formatter: (value: string) => (value ? formatDate(value, 'DD/MM/YYYY HH:mm') : value),
    headingCellClassName: 'w-36',
  },
  {
    label: 'View',
    key: 'view',
    type: 'link',
    href: '/submissions/{id}/contract',
    target: '_self',
    rel: 'noreferrer',
  },
];

export const Submissions: React.FC = () => {
  const { showModal } = useModal();
  const {
    sortOrder,
    data,
    status,
    filters,
    tags,
    getClearedFilters,
    setFilters,
    toggleSortOrderDirection,
    onFilterChange,
  } = useDataQuery(
    'submissions',
    initialFilters,
    fetchSubmissions,
    (data) => {
      const filtersWithRefOptions = insertNewOptions(filters, accumulateFilterOptions(data, 'ref'), 'ref');
      setFilters([...filtersWithRefOptions]);
      const filtersWithNameOptions = insertNewOptions(filters, accumulateFilterOptions(data, 'name'), 'name');
      setFilters([...filtersWithNameOptions]);
      const filtersWithUmrOptions = insertNewOptions(filters, accumulateFilterOptions(data, 'umr'), 'umr');
      setFilters([...filtersWithUmrOptions]);
      const filtersWithCreatedByOptions = insertNewOptions(
        filters,
        accumulateFilterOptions(data, 'created_by'),
        'created_by',
      );
      setFilters([...filtersWithCreatedByOptions]);
      const filtersWithSourceResourceDisplayNameOptions = insertNewOptions(
        filters,
        accumulateFilterOptions(data, 'sourceResourceDisplayName'),
        'sourceResourceDisplayName',
      );
      setFilters([...filtersWithSourceResourceDisplayNameOptions]);
      const filtersWithBrandingOptions = insertNewOptions(
        filters,
        accumulateFilterOptions(data, 'branding'),
        'branding',
      );
      setFilters([...filtersWithBrandingOptions]);
    },
    {
      fieldName: 'ref',
      sortDirection: 'desc',
      defaultFieldValue: '',
    },
  );

  const actions: Action[] = [
    {
      key: 'add_submission',
      label: 'New Submission',
      onClick: () => showModal(MODALS.ADMIN_CREATE_TEMPLATE, { data }),
    },
  ];

  return (
    <Table
      actions={actions}
      columns={columns}
      filteredData={data}
      filters={filters}
      getClearedFilters={getClearedFilters}
      onFilterChange={onFilterChange}
      sortOrder={sortOrder}
      status={status}
      tags={tags}
      toggleSortOrderDirection={toggleSortOrderDirection}
    />
  );
};

export const UserSubmissionsPage = withMenuLayout(Submissions);
