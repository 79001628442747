import pointer from 'json-pointer';
import traverse from 'json-schema-traverse';
import { cloneDeep, get, mergeWith, set, unset } from 'lodash-es';

import ajv from '../../helpers/schema/ajv';

const customizer = (objValue: any, srcValue: any) => {
  if (Array.isArray(objValue)) {
    return srcValue.length === 0 ? [] : objValue.concat(srcValue.filter((item: any) => objValue.indexOf(item) === -1));
  }
};
export const resolveRefs = (schema: any) => {
  traverse(schema, {
    cb: (currSchema) => {
      if ('$ref' in currSchema) {
        const pathToDefinition = currSchema?.['$ref']?.split('#')?.[1];
        const definition = pointer.get(schema, pathToDefinition) || {};

        mergeWith(currSchema, definition);
        unset(currSchema, '$ref');
      }
    },
  });
};

export const resolveFieldRefs = (schema: any, field: any) => {
  const clonedField = cloneDeep(field);

  traverse(clonedField, {
    cb: (currSchema) => {
      if ('$ref' in currSchema) {
        const pathToDefinition = currSchema?.['$ref']?.split('#')?.[1];
        const definition = pointer.get(schema, pathToDefinition) || {};

        mergeWith(currSchema, definition);
        unset(currSchema, '$ref');
      }
    },
  });

  return clonedField;
};
const getFormValuesForSection = (formValues: any, sectionIndex: any) => {
  const clonedFormValues = cloneDeep(formValues);
  clonedFormValues?.risk_details?.split_by_sections?.forEach((path: any) => {
    set(clonedFormValues, path, [get(clonedFormValues, `${path}[${sectionIndex}]`)]);
  });
  return clonedFormValues;
};
export const resolveSchemas = (schema: any, formValues: any) => {
  const sections = formValues?.risk_details?.sections ?? [];
  const parsedSections = sections?.length === 0 ? [{}] : sections;

  return parsedSections.map((val: any, key: any) => {
    return resolveSchema(cloneDeep(schema), getFormValuesForSection(formValues, key));
  });
};

// @ts-expect-error
export const resolveSchema = (parentSchema: any, formValues = {}, forceConditionalFields: boolean = false) => {
  const hasProperties = 'properties' in parentSchema;
  const hasAllOf = 'allOf' in parentSchema;
  const hasIf = 'if' in parentSchema;
  const hasThen = 'then' in parentSchema;
  const hasElse = 'else' in parentSchema;

  if (hasAllOf) {
    parentSchema?.allOf?.forEach((allOfItem: { if: boolean | object; then: any }) => {
      if (forceConditionalFields || ajv.compile(allOfItem.if)(formValues)) {
        mergeWith(parentSchema, allOfItem.then, customizer);
      }
    });
    unset(parentSchema, 'allOf');
    return resolveSchema(parentSchema, formValues, forceConditionalFields);
  }

  if (hasIf && hasProperties) {
    const isSubschemaValid = forceConditionalFields || ajv.compile(parentSchema.if)(formValues);

    if (isSubschemaValid && hasThen) {
      mergeWith(parentSchema, parentSchema.then, customizer);
    }

    if (!isSubschemaValid && hasElse) {
      mergeWith(parentSchema, parentSchema.else, customizer);
    }

    unset(parentSchema, 'if');

    return resolveSchema(parentSchema, formValues, forceConditionalFields);
  }

  if (hasProperties) {
    Object.keys(parentSchema.properties).forEach((key) => {
      if (forceConditionalFields) return resolveSchema(parentSchema.properties[key], {}, true);

      // @ts-expect-error
      if (typeof formValues[key] === 'object' && formValues[key] !== null) {
        // @ts-expect-error
        return resolveSchema(parentSchema.properties[key], formValues[key], forceConditionalFields);
      }
    });
  }
  return parentSchema;
};

export default resolveSchema;
