import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@components/Button';
import Modal from '@components/Modal';
import { Show, ShowFirstMatching } from '@components/Show';
import { useEntityStore } from '@ContractBuilder/store';
import type { EndorsementData } from '@ContractBuilder/types';
import { canChange } from '@helpers/canChange';
import { useEffectOnce } from '@hooks/useEffectOnce';
import { createEndorsement } from '@mutations/createEndorsement';
import Nav from '@src/routes';
import { get } from 'lodash-es';
import qs from 'qs';

import { EndorsementPremiumChange } from '../constants';

import { EndorsementForm } from './components/EndorsementForm';
import { FormLoading } from './components/FormLoading';
import type { FormState } from './types';
import { isFormValid } from './types';

type CreateEndorsementModalProps = {
  handleClose: () => void;
  isEdit?: boolean;
  refetch: any;
};

export const EndorsementModal = ({ handleClose, isEdit = false, refetch }: CreateEndorsementModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const { endorsement, updateEndorsement } = useEntityStore(({ submission, updateResourceData }) => ({
    endorsement: submission as EndorsementData,
    updateEndorsement: updateResourceData,
  }));

  const submissionId = useParams().id;
  const create = createEndorsement(submissionId!);

  const openEndorsement = (id: string) => {
    if (id && submissionId) {
      const route = Nav.EndorsementContract.replace(':id', submissionId).replace(':endorsement_id', id);
      const win = window.open(route, '_self');
      win?.focus();
    }
  };

  const [state, setState] = useState<FormState>({
    premium_change: EndorsementPremiumChange.NONE,
  });

  const setFormValue = (name: keyof FormState, value?: string | object) => {
    setState((prev) => {
      const additionalPremium =
        name === 'premium_change' && value !== prev.premium_change ? undefined : prev.additional_premium;

      return { ...prev, additional_premium: additionalPremium, [name]: value };
    });
  };

  useEffectOnce(() => {
    const period = get(endorsement, 'data_items.risk_details.period[0]');

    if (isEdit && endorsement) {
      setState({
        additional_premium: endorsement.additional_premium,
        effective_date: endorsement.effective_date,
        effective_date_details: endorsement.effective_date_details,
        expiry_date: endorsement.expiry_date,
        premium_change: endorsement.premium_change || EndorsementPremiumChange.NONE,
        reason: endorsement.reason,
        reference: endorsement.reference,
        type: endorsement.type,
      });
    } else if (period?.period_matrix) {
      const parsedExpiryDate = get(period, 'period_matrix.to.date');
      setFormValue('expiry_date', parsedExpiryDate);
    }

    return setIsInitialized(true);
  });

  const handleCreate = async () => {
    if (!isFormValid(state)) {
      return;
    }

    setIsLoading(true);
    try {
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      const { id } = await create(state, queryParams.authToken as string);
      refetch();
      openEndorsement(id);
      handleClose();
    } catch (_error) {
      // Swallow the error for now
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = async () => {
    if (!isFormValid(state)) {
      return;
    }

    setIsLoading(true);
    try {
      await updateEndorsement(state);
      return handleClose();
    } catch (_error) {
      // Swallow the error for now
    } finally {
      setIsLoading(false);
    }
  };

  const canEditEndorsement = !isEdit || canChange(endorsement.status);
  const isDisabled = !isFormValid(state) || !canEditEndorsement;
  const modalTitle = isEdit ? 'Edit endorsement' : 'Create endorsement';

  return (
    <Modal open className="w-full sm:w-5/6 lg:w-1/2" onClose={handleClose} title={modalTitle}>
      <div className="grid grid-cols-2 gap-5">
        <ShowFirstMatching>
          <Show when={isInitialized}>
            <EndorsementForm setFormValue={setFormValue} state={state} isReadOnly={!canEditEndorsement} />
          </Show>
          <Show when={!isInitialized}>
            <FormLoading />
          </Show>
        </ShowFirstMatching>
      </div>
      <div className="mt-8 flex w-full justify-between gap-4">
        <Button isDisabled={isLoading} onClick={handleClose} className="w-full">
          Cancel
        </Button>
        <Button
          onClick={isEdit ? handleEdit : handleCreate}
          className="w-full"
          kind="primary"
          isDisabled={isDisabled}
          loading={isLoading}
        >
          {isEdit ? 'Update' : 'Create'}
        </Button>
      </div>
    </Modal>
  );
};
