import type { EndorsementData, EntityData } from '@ContractBuilder/types';
import type { ClauseTypes, ResourceBlock } from '@root/@types/types';
import { getAllBlocks } from '@root/helpers/blocks';
import { filter, flow, groupBy, partialRight } from 'lodash-es';

export type GroupedClauses = Record<ClauseTypes, ResourceBlock[]>;

export const getGroupedClauses = (entity?: EntityData): GroupedClauses => {
  if (!entity?.sections) {
    return {} as GroupedClauses;
  }

  return flow(
    partialRight(getAllBlocks),
    partialRight(filter, (block: ResourceBlock) => !block.deleted_at && block.type === 'clause'),
    partialRight(groupBy, 'clause_type'),
  )(entity);
};

export const getEndorsementRemovedGroupedClauses = (entity?: EndorsementData): GroupedClauses => {
  if (!entity?.sections) {
    return {} as GroupedClauses;
  }

  return flow(
    partialRight(filter, (block: ResourceBlock) => block.isDeleted && block.type === 'clause'),
    partialRight(groupBy, 'clause_type'),
  )(entity.changed_blocks ?? []);
};
