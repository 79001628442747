import axios from 'axios';

import type { EndorsementData } from '../containers/ContractBuilder/types';
import { getRequestAuth } from '../helpers/getRequestAuth';

const getUrl = (submissionId: string) =>
  [
    //
    `${import.meta.env.VITE_API_URL}`,
    `submissions-spa-al`,
    submissionId,
    `endorsements-spa-al`,
  ].join('/');

export const fetchEndorsements = async (submissionId: string, authToken: string): Promise<EndorsementData[]> => {
  const auth = await getRequestAuth(authToken);

  const params = new URLSearchParams();

  const response = await axios({
    method: 'get',
    url: getUrl(submissionId),
    params,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
  });

  return response.data;
};
