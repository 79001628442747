import type { Submission, UpdateEndorsementDataPayload } from '@root/@types/types';
import axios from 'axios';

import { getRequestAuth } from '../helpers/getRequestAuth';

export const createEndorsement =
  (submissionId: string) =>
  async (data: UpdateEndorsementDataPayload, authToken: string): Promise<Pick<Submission, 'id'>> => {
    const auth = await getRequestAuth(authToken);
    const response = await axios({
      method: 'post',
      url: `${import.meta.env.VITE_API_URL}/submissions-spa-al/${submissionId}/endorsements-spa-al`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth.token,
      },
      data,
    });

    return response?.data;
  };
