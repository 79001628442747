import type { FC } from 'react';
import React from 'react';
import Icon from '@components/Icon';
import IconMdi from '@components/IconMdi';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { mdiMinus, mdiPlus } from '@mdi/js';
import type { JSONSchema } from '@root/@types/types';
import mapChildren from '@root/src/mapChildren';
import { cloneDeep, get } from 'lodash-es';

import resolveSchema from '../../../helpers/resolveSchema';
import Button from '../../Button';
import Card from '../../Card';
import ErrorText from '../../ErrorText';
import CollapsibleValidatedItem from '../CollapsibleValidatedItem/CollapsibleValidatedItem';

import type { SectionRepeaterViewProps } from './types';

export const SectionRepeaterCollapsibleView: FC<SectionRepeaterViewProps> = ({
  canAdd,
  className,
  emptyContent,
  handleAdd,
  handleRemove,
  helperText,
  id,
  initialItems,
  isDisabled,
  isReadOnly = false,
  item,
  onChange,
  ownKey,
  schemaItem,
  showQuestionKey,
  startCollapsed = false,
  title,
  total100Error,
  validationErrors,
  hideErrors,
  isRequired,
}) => {
  const { formValues } = useDatapointsStore(({ formValues }) => ({ formValues }));

  return (
    <CollapsibleValidatedItem
      className={className}
      id={id}
      title={title}
      ownKey={ownKey}
      helperText={helperText}
      startCollapsed={startCollapsed}
      isRequired={isRequired}
    >
      {emptyContent && (
        <div className="mb-4 mt-2 flex items-center gap-x-3.5 rounded-md bg-info-50 px-3 py-4 text-xs font-medium text-info-600">
          <Icon name="book" />
          {emptyContent}
        </div>
      )}
      {initialItems.map((_lineItem, index) => {
        const key = `${ownKey}[${index}]`;
        const clonedSchema = cloneDeep(schemaItem);

        resolveSchema(clonedSchema, get(formValues, key));

        return (
          <Card
            className="mb-8"
            key={key}
            header={
              <Card.Header className="flex items-center justify-between p-0 pb-4">
                <div className="font-medium">
                  {item.items?.title} #{index + 1}
                </div>
              </Card.Header>
            }
          >
            {mapChildren({
              onChange,
              parentKey: key,
              parentSchema: clonedSchema as JSONSchema,
              validationErrors,
              showQuestionKey,
              isReadOnly,
              isDisabled,
              hideErrors,
            })}
          </Card>
        );
      })}
      {total100Error && <ErrorText className="mb-4">{total100Error.message}</ErrorText>}
      {!isReadOnly &&
        (item?.minimum === undefined || item?.minimum < initialItems.length) &&
        initialItems.length > 0 && (
          <Button
            className="mr-4 h-10"
            kind="danger"
            data-testid={`${id}-remove-section-button`}
            onClick={() => handleRemove(initialItems.length - 1)}
          >
            <IconMdi path={mdiMinus} />
            {`Remove ${item.title ?? 'a section'}`}
          </Button>
        )}
      {!isReadOnly && canAdd && (
        <Button onClick={handleAdd} kind="primary" className="h-10">
          <IconMdi path={mdiPlus} />
          {`Add ${item.title ?? 'a section'}`}
        </Button>
      )}
    </CollapsibleValidatedItem>
  );
};
