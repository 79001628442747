import { StandardisedSectionName } from '@root/@types/types';

export const SECTIONS_KEYS_SELECTOR = [
  { value: StandardisedSectionName.RiskDetails, label: 'Risk Details', order: 0 },
  { value: StandardisedSectionName.Information, label: 'Information', order: 1 },
  { value: StandardisedSectionName.SecurityDetails, label: 'Security Details', order: 2 },
  { value: StandardisedSectionName.SubscriptionAgreement, label: 'Subscription Agreement', order: 3 },
  { value: StandardisedSectionName.FiscalAndRegulatory, label: 'Fiscal and Regulatory', order: 4 },
  {
    value: StandardisedSectionName.BrokerRemunerationAndDeductions,
    label: 'Broker Remuneration & Deductions',
    order: 5,
  },
];
