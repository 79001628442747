import { forwardRef } from 'react';
import { Show } from '@components/Show';
import { TooltipedIcon } from '@components/TooltipedIcon';
import { useFieldSchemaIds } from '@hooks/use-schema-ids';
import { mdiHelpCircleOutline } from '@mdi/js';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';

import { alwaysArray } from '../../utils/always-array';
import DescriptionText from '../DescriptionText';
import ErrorText from '../ErrorText';
import { FieldSchemaIdTooltip } from '../FieldCdrId';
import Icon from '../Icon';
import Label from '../Label';

import { inputFieldClasses } from './classes';
import type { InputFieldProps } from './types';

export const InputField = forwardRef<HTMLDivElement, InputFieldProps>(function InputField(
  {
    cdrId,
    cdrName,
    children,
    className,
    descriptionText,
    endAdornment,
    errors,
    helperText,
    iconName,
    iconPosition = 'right',
    id,
    isRequired,
    labelIcon,
    labelText,
    link,
    mrcId,
    name,
    hideErrors,
    shortName,
    size = 'md',
    startAdornment,
  },
  ref,
) {
  const { schemaId, parsedCdrIds, parsedCdrNames } = useFieldSchemaIds({ cdrId, cdrName, mrcId });
  const errorsArray = alwaysArray(errors);
  const hasErrors = !isEmpty(errorsArray);
  const isErrorVisible = hasErrors && !hideErrors;
  return (
    <div
      data-error={hasErrors}
      className={clsx('relative', !clsx(className).match(/w-/) && 'w-full', className)}
      ref={ref}
    >
      <Show when={!!labelText}>
        <div
          className={clsx(
            'mb-2 flex text-sm font-medium text-info-700',
            helperText || labelIcon ? 'justify-start gap-2' : 'justify-between',
          )}
        >
          <FieldSchemaIdTooltip
            schemaId={schemaId}
            cdrIds={parsedCdrIds}
            cdrNames={parsedCdrNames}
            fallbackName={labelText}
          >
            <div>
              <Label htmlFor={id ?? name} isRequired={isRequired} schemaId={schemaId}>
                {shortName ?? labelText}
              </Label>
            </div>
          </FieldSchemaIdTooltip>
          {link}
          {!!helperText && <TooltipedIcon path={mdiHelpCircleOutline} placement="top" content={helperText as string} />}
          {labelIcon}
        </div>
      </Show>
      <div className="relative">
        {startAdornment}
        <Show when={!!iconName}>
          <div className={inputFieldClasses.inner({ size, iconPosition })}>
            <Icon name={iconName!} className="w-3 fill-current text-info-800" />
          </div>
        </Show>
        {children}
        {endAdornment}
      </div>
      <Show when={!!descriptionText}>
        {<DescriptionText id={`${name}Description`}>{descriptionText}</DescriptionText>}
      </Show>
      <Show when={isErrorVisible}>{errorsArray?.map((error) => <ErrorText key={error}>{error}</ErrorText>)}</Show>
    </div>
  );
});
