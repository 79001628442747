import { DocumentMode } from '@root/@types/types';

import { getDocumentMode } from './getDocumentMode';

export const getCurrentDocumentApiUrl = (pathname: string, documentMode?: DocumentMode, entityId?: string): string => {
  const {
    mode,
    ids: [$1, $2],
  } = getDocumentMode(pathname);

  const parsedDocumentMode = documentMode ?? mode;
  const isTemplate = parsedDocumentMode === DocumentMode.TEMPLATES;
  const isEndorsement = parsedDocumentMode === DocumentMode.ENDORSEMENTS;
  const variant = isTemplate ? 'templates' : 'submissions';

  const url = [
    //
    import.meta.env.VITE_API_URL,
    `${variant}-spa-al`,
    $1,
    isEndorsement && $2 && 'endorsements-spa-al',
    isEndorsement && $2,
    entityId,
  ]
    .filter(Boolean)
    .join('/');

  return url;
};
