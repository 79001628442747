import { mdiArchive, mdiCreationOutline } from '@mdi/js';
import { getDataTestId } from '@root/src/utils/element-testid-selectors';

import { useBlockCreator } from '../../context';
import { StepperCard } from '../Card';
import { StepView } from '../StepView';

export const StepStart = () => {
  const { setStep } = useBlockCreator();

  return (
    <StepView isValid stepId="start">
      <div className={'m-auto flex h-full w-fit items-center gap-10'}>
        <StepperCard
          cta="Select block"
          icon={mdiArchive}
          description="Select a block or clause from library"
          onClick={() => setStep('library')}
          data-testid={getDataTestId('block.addFromLibrary').toString()}
        />
        <StepperCard
          cta="Create block"
          icon={mdiCreationOutline}
          description="Create a new custom block"
          onClick={() => setStep('block')}
          data-testid={getDataTestId('block.addCustom').toString()}
        />
      </div>
    </StepView>
  );
};
