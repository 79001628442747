import { getBlockDeletedCopy } from '@ContractBuilder/modules/block-content/constants/constants';
import type { ClauseTypes, ResourceBlock } from '@root/@types/types';
import { CLAUSE_TYPES } from '@User/Blocks/constants';
import { flow, isEmpty, map, partialRight, reduce } from 'lodash-es';

import type { BlockMentionValues } from './get-block-mention-values';
import type { GroupedClauses } from './get-grouped-clauses-list';

const CLAUSE_NAME_FALLBACK = 'Untitled clause';

export const getClauseListEntryContent = ({ clause_reference, name }: ResourceBlock) => {
  return clause_reference ? `${clause_reference} - ${name || CLAUSE_NAME_FALLBACK}` : name || CLAUSE_NAME_FALLBACK;
};

export const getClauseList = (
  blocks: ResourceBlock[] = [],
  type: ClauseTypes,
  removedBlocks: ResourceBlock[] = [],
): string => {
  if (blocks.length === 0) return `No ${type.toLocaleLowerCase()} specified`;

  return [
    '<ul>',
    ...blocks.map((block) => [`<li>`, getClauseListEntryContent(block), '</li>'].join('')),
    ...removedBlocks.map((block) => [`<li>`, getBlockDeletedCopy(getClauseListEntryContent(block)), '</li>'].join('')),
    '</ul>',
  ].join('');
};

export type ClauseListKey =
  | 'list_of_condition'
  | 'list_of_endorsement'
  | 'list_of_exclusion'
  | 'list_of_express_warranties'
  | 'list_of_standard_warranties'
  | 'list_of_wording';

export const keyMap: Record<ClauseTypes, ClauseListKey> = {
  Condition: 'list_of_condition',
  Endorsement: 'list_of_endorsement',
  Exclusion: 'list_of_exclusion',
  Wording: 'list_of_wording',
  'Express Warranties': 'list_of_express_warranties',
  'Standard Warranties': 'list_of_standard_warranties',
};

export const getClauseTypeForClauseListKey = (value: ClauseListKey): ClauseTypes | undefined => {
  const keys = Object.keys(keyMap) as ClauseTypes[];
  return keys.find((key) => keyMap[key] === value);
};

export const getClausesAsMentions = (
  clauses: GroupedClauses | {},
  removedClauses: GroupedClauses | {} = {},
): BlockMentionValues => {
  if (isEmpty(clauses)) return {};

  const lists: Record<ClauseListKey, string> = flow(
    partialRight(map, (type: ClauseTypes) => ({
      // @ts-expect-error
      [keyMap[type]]: getClauseList(clauses[type], type, removedClauses[type]),
    })),
    partialRight(reduce, (acc: any, next: any) => ({ ...acc, ...next }), {}),
  )(CLAUSE_TYPES);

  return lists;
};
