import { getBlockDeletedCopy } from '@ContractBuilder/modules/block-content';
import type { EntityData } from '@ContractBuilder/types';
import type { BlockMentionValues } from '@ContractBuilder/utils';
import { getGroupedClauses, replaceMention } from '@ContractBuilder/utils';
import type { ClauseListKey } from '@ContractBuilder/utils/get-clauses-as-mentions';
import {
  getClauseListEntryContent,
  getClauseTypeForClauseListKey,
} from '@ContractBuilder/utils/get-clauses-as-mentions';
import type { ResourceBlock } from '@root/@types/base';
import { isNonNullish } from '@root/helpers';
import htmlDiff from 'html-diff-ts';
import { uniqBy } from 'lodash-es';
import { parse } from 'node-html-parser';

export const getOldText = (
  block: ResourceBlock,
  parentBlock?: ResourceBlock,
  endorsementParentMentionValues: BlockMentionValues = {},
  mentionValues: BlockMentionValues = {},
) => {
  const { isAdded, isDeleted } = block;

  if (isAdded) {
    return '';
  }

  let oldText = parentBlock?.content ?? block.content ?? '';

  Object.keys(endorsementParentMentionValues).forEach((key) => {
    oldText = replaceMention(key, endorsementParentMentionValues[key], oldText, true, true, isDeleted) ?? oldText;
  });

  Object.keys(mentionValues).forEach((key) => {
    if (!endorsementParentMentionValues[key]) {
      oldText = replaceMention(key, '', oldText, true, true, isDeleted) ?? oldText;
    }
  });

  return oldText;
};

export const getNewText = (
  block: ResourceBlock,
  endorsementParentMentionValues: BlockMentionValues = {},
  mentionValues: BlockMentionValues = {},
) => {
  const { content = '', isDeleted = false } = block;
  let newText = content;

  if (isDeleted) {
    return '';
  }

  Object.keys(mentionValues).forEach((key) => {
    newText = replaceMention(key, mentionValues[key], newText, true, true, isDeleted) ?? newText;
  });

  Object.keys(endorsementParentMentionValues).forEach((key) => {
    if (!mentionValues[key]) {
      newText = replaceMention(key, '', newText, true, true, isDeleted) ?? newText;
    }
  });

  return newText;
};

/** Replace removed clauses diff in clause mention lists with custom content `... has hereby been removed` **/
export const getParsedOutput = (
  oldText: string,
  newText: string,
  endorsementParent?: EntityData,
  endorsement?: EntityData,
): string => {
  const diff = htmlDiff(oldText, newText);
  const doc = parse(diff);

  const endorsementParentUsedClauses = getGroupedClauses(endorsementParent);
  const endorsementUsedClauses = getGroupedClauses(endorsement);

  doc.querySelectorAll('span.mention-list').forEach((item) => {
    const key = item.getAttribute('data-id') as ClauseListKey;
    const type = getClauseTypeForClauseListKey(key);

    if (!type) {
      return;
    }

    const relevantEndorsementParentClauses = endorsementParentUsedClauses[type] ?? [];
    const relevantEndorsementClauses = endorsementUsedClauses[type] ?? [];

    const combined = uniqBy([...relevantEndorsementClauses, ...relevantEndorsementParentClauses], 'id');
    const innerHTML = combined
      .map((clause) => {
        const prev = relevantEndorsementParentClauses.find((block) => block.id === clause.id);
        const next = relevantEndorsementClauses.find((block) => block.id === clause.id);

        if (!prev && next) {
          return `<li>${htmlDiff('', getClauseListEntryContent(next))}</li>`;
        }

        if (prev && !next) {
          return `<li>${htmlDiff(getBlockDeletedCopy(prev.name), '')}</li>`;
        }

        if (prev && next) {
          return `<li>${htmlDiff(getClauseListEntryContent(prev), getClauseListEntryContent(next))}</li>`;
        }

        return undefined;
      })
      .filter(isNonNullish);

    const parsedContent = parse(`<ul class="!my-0">${innerHTML.join('')}</ul>`);

    if (isNonNullish(item.parentNode?.parentNode) && item.parentNode?.rawTagName === 'p') {
      return item.parentNode.replaceWith(parsedContent);
    }

    return item.replaceWith(parsedContent);
  });

  return doc.toString();
};
