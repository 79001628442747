import React from 'react';
import ItemTeams from '@pages/User/components/ItemTeams';
import { accumulateFilterOptions, insertNewOptions } from '@pages/User/components/prepareDropdownOptions';
import type { Action, Column, Filter, User, UserRole } from '@root/@types/types';
import { tenantConfig } from '@root/config/tenants';
import language from '@root/src/language/language';
import withMenuLayout from '@root/src/layout/withMenuLayout';
import { useModal } from '@src/hooks';
import { fetchUsers } from '@src/queries';
import dayjs from 'dayjs';

import { MODALS } from '../../constants';

import { Table } from './components';
import { useDataQuery } from './hooks';

const USER_STATUSES = [
  {
    value: 'Active',
    label: 'Active',
    predicate: (user: any) => user.enabled && ['CONFIRMED', 'EXTERNAL_PROVIDER'].includes(user.status),
  },
  {
    value: 'Pending',
    label: 'Pending',
    predicate: (user: any) => !user.status || user.status === 'FORCE_CHANGE_PASSWORD',
  },
  {
    value: 'Disabled',
    label: 'Disabled',
    predicate: (user: any) => user.enabled === false,
  },
];

const getStatusLabel = (user: any) => {
  return USER_STATUSES?.find(({ predicate }) => predicate(user))?.label ?? user?.status ?? '';
};

const USER_ROLES = Object.values(language.user.role).map((label) => ({
  value: label,
  label: label,
  predicate: (user: any) => user.role && (language.user.role as { [roleKey: string]: string })[user.role] === label,
}));

const Status = ({ user }: { user: any }) => {
  if (!user.status || user.status === 'FORCE_CHANGE_PASSWORD')
    return (
      <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
        Pending
      </span>
    );
  if (user.status === 'RESET_REQUIRED')
    return (
      <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
        Reset required
      </span>
    );
  if (user.enabled)
    return (
      <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
        Active
      </span>
    );
  else
    return (
      <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
        Disabled
      </span>
    );
};

const initialFilters: Filter[] = [
  {
    label: 'Role',
    key: 'role',
    type: 'dropdown',
    placeholder: 'All roles',
    options: USER_ROLES.map((i) => i.label),
    optionsMapping: USER_ROLES,
    selected: [],
    isPredicateFilter: true,
  },
  {
    label: 'Teams',
    key: 'teams',
    type: 'dropdown',
    placeholder: 'All teams',
    options: (tenantConfig.teams ?? []).map((i) => i.name),
    selected: [],
  },
  {
    label: 'Email',
    key: 'email',
    type: 'dropdown',
    placeholder: 'All emails',
    options: [],
    selected: [],
  },
  {
    label: 'Status',
    key: 'status',
    type: 'dropdown',
    placeholder: 'All statuses',
    options: USER_STATUSES.map((i) => i.label),
    optionsMapping: USER_STATUSES,
    selected: [],
    isPredicateFilter: true,
  },
];

export const Users: React.FC = () => {
  const { showModal } = useModal();

  const {
    sortOrder,
    data,
    status,
    filters,
    getClearedFilters,
    setFilters,
    toggleSortOrderDirection,
    setShouldFetchData,
    onFilterChange,
  } = useDataQuery('users', initialFilters, fetchUsers, (data) => {
    const filtersWithEmailOptions = insertNewOptions(filters, accumulateFilterOptions(data, 'email'), 'email');
    setFilters([...filtersWithEmailOptions]);
  });

  const initialColumns: Column[] = [
    {
      label: 'First Name',
      key: 'first_name',
      sortable: true,
    },
    {
      label: 'Last Name',
      key: 'last_name',
      sortable: true,
    },
    {
      label: 'Role',
      key: 'role',
      sortable: true,
      formatter: (value?: UserRole) => (value ? language.user.role[value] : value),
    },
    {
      label: 'Teams',
      key: 'teams',
      render: (item: any) => <ItemTeams teams={item.teams ?? []} />,
    },
    {
      label: 'Email',
      key: 'email',
      sortable: true,
    },
    {
      label: 'Status',
      key: 'status',
      sortable: true,
      headingCellClassName: '!w-24',
      render: (item: any) => (
        <td className="!w-24 whitespace-nowrap px-6 py-4 align-top">
          <Status user={item} />
        </td>
      ),
      normalizer: (value, user) => getStatusLabel(user),
    },
    {
      label: 'Created at',
      key: 'created_at',
      sortable: true,
      formatter: (value: string) => (value ? dayjs(value).format('DD/MM/YYYY HH:mm') : value),
      headingCellClassName: 'w-36',
    },
    {
      label: 'Updated at',
      key: 'updated_at',
      sortable: true,
      formatter: (value: string) => (value ? dayjs(value).format('DD/MM/YYYY HH:mm') : value),
      headingCellClassName: 'w-36',
    },
    {
      label: '',
      key: 'edit',
      render: (user: any) => (
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right align-top text-sm font-medium sm:pr-6">
          {(() => {
            if (user?.status === 'FORCE_CHANGE_PASSWORD')
              return (
                <a href="#" onClick={handleResendButtonClick(user)} className="text-primary-600 hover:text-primary-900">
                  Resend
                </a>
              );
            else
              return (
                <a href="#" onClick={handleEditUserClick(user)} className={'text-primary-600 hover:text-primary-900'}>
                  Edit
                </a>
              );
          })()}
        </td>
      ),
    },
  ];

  const handleResendButtonClick = (user: User) => () => showModal(MODALS.ADMIN_USER_RESEND_INVITE, { user });

  const handleEditUserClick = (user: User) => () =>
    showModal(MODALS.ADMIN_USER, { user, setShouldFetchData, isAdd: false });

  const actions: Action[] = [
    {
      key: 'add_user',
      label: 'New User',
      onClick: () => {
        showModal(MODALS.ADMIN_USER, { setShouldFetchData, isAdd: true });
      },
    },
  ];

  return (
    <Table
      actions={actions}
      columns={initialColumns}
      filters={filters}
      getClearedFilters={getClearedFilters}
      onFilterChange={onFilterChange}
      sortOrder={sortOrder}
      toggleSortOrderDirection={toggleSortOrderDirection}
      filteredData={data}
      status={status}
    />
  );
};

export const UserUsersPage = withMenuLayout(Users);
