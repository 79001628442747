import { getRequestAuth } from '@helpers/getRequestAuth';
import axios from 'axios';

import type { DynamoDBLibraryBlock, ServerResponse } from '../../@types/types';

export const fetchBlock = async (id: string, authToken?: string): Promise<ServerResponse<DynamoDBLibraryBlock>> => {
  const auth = await getRequestAuth(authToken);

  const response = await axios({
    method: 'get',
    url: `${import.meta.env.VITE_API_URL}/blocks-spa-al/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
  });

  return response.data;
};
