import type { FC } from 'react';
import React from 'react';
import ItemTeams from '@pages/User/components/ItemTeams';
import { accumulateFilterOptions, insertNewOptions } from '@pages/User/components/prepareDropdownOptions';
import StatusBadge from '@pages/User/components/StatusBadge';
import type { Action, Column, Filter } from '@root/@types/types';
import { TEMPLATE_STATUSES } from '@root/@types/types';
import { tenantConfig } from '@root/config/tenants';
import { formatDate } from '@root/helpers';
import { ALL_CLASSES_OF_BUSINESS } from '@root/helpers/constants';
import withMenuLayout from '@root/src/layout/withMenuLayout';
import { useModal } from '@src/hooks';
import { fetchTemplates } from '@src/queries';

import { MODALS } from '../../constants';

import ItemTags from './components/ItemTags';
import { Table } from './components';
import { useDataQuery } from './hooks';
const columns: Column[] = [
  {
    label: 'Template ID',
    key: 'ref',
    sortable: true,
    headingCellClassName: '!w-36',
    dataCellClassName: '!w-36',
    normalizer: (fieldValue) => Number(fieldValue.replace('ART-T-', '')),
  },
  {
    label: 'Name',
    key: 'name',
    sortable: true,
  },
  {
    label: 'Teams',
    key: 'teams',
    headingLinkClassName: 'pl-0',
    dataCellClassName: 'truncate whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-info-900 sm:pl-6',
    render: (item: any) => <ItemTeams teams={item.teams ?? []} />,
  },
  {
    label: 'Status',
    key: 'status',
    sortable: true,
    headingCellClassName: '!w-24',
    headingLinkClassName: '!pl-0',
    render: (item: any) => <StatusBadge status={item.status} />,
  },
  {
    label: 'Tags',
    key: 'tags',
    headingCellClassName: '!pl-0',
    dataCellClassName: '',
    render: (item: any, tags, setIsLoading) => (
      <ItemTags item={item} tags={tags ?? []} setIsLoading={setIsLoading} queryKey="templates" />
    ),
  },
  {
    label: 'Updated at',
    key: 'updated_at',
    sortable: true,
    formatter: (value: string) => (value ? formatDate(value, 'DD/MM/YYYY HH:mm') : value),
    headingCellClassName: '!w-[8.3rem]',
    headingLinkClassName: '!pl-0',
    dataCellClassName: '!w-[8.3rem] !pl-0',
  },
  {
    label: 'View',
    key: 'view',
    type: 'link',
    href: '/templates/{id}/contract',
    target: '_self',
    rel: 'noreferrer',
  },
];

const initialFilters: Filter[] = [
  {
    label: 'Template ID',
    key: 'ref',
    type: 'dropdown',
    placeholder: 'All IDs',
    options: [],
    selected: [],
  },
  {
    label: 'Template name',
    key: 'name',
    type: 'dropdown',
    placeholder: 'All templates',
    options: [],
    selected: [],
  },
  {
    label: 'Class of Business',
    key: 'class_of_business',
    type: 'dropdown',
    placeholder: 'All classes',
    options: ALL_CLASSES_OF_BUSINESS,
    selected: [],
  },
  {
    label: 'Teams',
    key: 'teams',
    type: 'dropdown',
    placeholder: 'All teams',
    options: (tenantConfig.teams ?? []).map((i) => i.name),
    selected: [],
  },
  {
    label: 'Status',
    key: 'status',
    type: 'dropdown',
    placeholder: 'All statuses',
    options: TEMPLATE_STATUSES,
    selected: TEMPLATE_STATUSES.filter((status) => status !== 'ARCHIVED'),
  },
  {
    label: 'Tags',
    key: 'tags',
    type: 'dropdown',
    placeholder: 'All tags',
    options: [],
    selected: [],
  },
];

export const Templates: FC = () => {
  const { showModal } = useModal();
  const {
    data,
    status,
    filters,
    sortOrder,
    tags,
    getClearedFilters,
    setFilters,
    toggleSortOrderDirection,
    onFilterChange,
  } = useDataQuery(
    'templates',
    initialFilters,
    fetchTemplates,
    (data) => {
      const filtersWithRefOptions = insertNewOptions(filters, accumulateFilterOptions(data, 'ref'), 'ref');
      setFilters([...filtersWithRefOptions]);
      const filtersWithNameOptions = insertNewOptions(filters, accumulateFilterOptions(data, 'name'), 'name');
      setFilters([...filtersWithNameOptions]);
    },
    {
      fieldName: 'ref',
      sortDirection: 'desc',
      defaultFieldValue: '',
    },
  );

  const actions: Action[] = [
    {
      key: 'add_template',
      label: 'New Template',
      onClick: () => {
        showModal(MODALS.ADMIN_CREATE_TEMPLATE, { data });
      },
    },
  ];

  return (
    <Table
      actions={actions}
      columns={columns}
      filters={filters}
      getClearedFilters={getClearedFilters}
      onFilterChange={onFilterChange}
      sortOrder={sortOrder}
      toggleSortOrderDirection={toggleSortOrderDirection}
      filteredData={data}
      status={status}
      tags={tags}
    />
  );
};

export const UserTemplatesPage = withMenuLayout(Templates);
