import axios from 'axios';

import type { PdfDocumentType, ServerResponse } from '../../@types/types';
import { getRequestAuth } from '../helpers/getRequestAuth';

export const getPdfDocument = async (
  submissionOrTemplateId: string,
  endorsementId: string,
  authToken: string,
  resourceName: 'templates' | 'submissions' | 'endorsements',
  pdfDocumentType: PdfDocumentType,
  expectedVersion?: number,
): Promise<ServerResponse<{ url: string; documentVersion: number }>> => {
  const auth = await getRequestAuth(authToken);

  let url = `${import.meta.env.VITE_API_URL}/${resourceName}-spa-${
    auth.authProvider
  }/${submissionOrTemplateId}/pdf/download?type=${pdfDocumentType}`;

  if (resourceName === 'endorsements') {
    url = `${import.meta.env.VITE_API_URL}/submissions-spa-${
      auth.authProvider
    }/${submissionOrTemplateId}/${resourceName}-spa-${
      auth.authProvider
    }/${endorsementId}/pdf/download?type=${pdfDocumentType}`;
  }

  try {
    const response = await axios.create().get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth.token,
      },
    });

    const serverResponse: ServerResponse<{ url: string; documentVersion: number }> = response.data;
    if (expectedVersion && serverResponse?.data?.documentVersion < expectedVersion) {
      return Promise.reject('Pdf version does not match');
    }

    return serverResponse;
  } catch (err: any) {
    if (err?.response) {
      return Promise.reject(
        `Pdf download request failed with status: ${err.response?.status} and message: ${JSON.stringify(
          err.response?.data,
        )}`,
      );
    } else {
      return Promise.reject(`Pdf download request failed with message: ${err.message}`);
    }
  }
};
