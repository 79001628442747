import { Auth } from '@aws-amplify/auth';
import type { AmplifyUser } from '@root/@types/types';
import * as Sentry from '@sentry/browser';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';
import { create } from 'zustand';

interface UserStoreBase {
  setUser: (user: AmplifyUser | null) => void;
  refreshUser: (updatedAt: string) => void;
  updatedAt: string;
}

interface UseUserStoreAuthenticated extends UserStoreBase {
  user: AmplifyUser;
  isLoggedIn: true;
}

interface UseUserStoreNotAuthenticated extends UserStoreBase {
  user: null;
  isLoggedIn: false;
}

export const useUserStore = create<UseUserStoreAuthenticated | UseUserStoreNotAuthenticated>((set) => ({
  isLoggedIn: false,
  updatedAt: '',
  user: null,
  setUser: (user) => {
    if (!user) {
      return set({ user, isLoggedIn: false });
    }

    return set({ user, isLoggedIn: true });
  },
  refreshUser: async (updatedAt: string) => {
    const amplifyUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const currentSession = await Auth.currentSession();
    amplifyUser.refreshSession(currentSession.getRefreshToken(), async (err: any, session: CognitoUserSession) => {
      if (err) {
        Sentry.setUser(null);
        await Auth.signOut();
        return set({ user: null, isLoggedIn: false, updatedAt: '' });
      }
      amplifyUser.setSignInUserSession(session);
      const user = await Auth.currentUserInfo();
      set({ user, isLoggedIn: true, updatedAt });
    });
  },
}));
