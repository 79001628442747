import type { FC } from 'react';
import { RadioGroup } from '@headlessui/react';

import { toggleGroupButton } from './classes';
import type { ToggleOptionProps } from './types';

export const ToggleOption: FC<ToggleOptionProps> = ({
  position,
  option,
  size,
  isIncomplete,
  onClick,
  disabled,
  checked,
}) => {
  const { label, value } = option;

  return (
    <RadioGroup.Option
      onClick={() => onClick(value)}
      value={value}
      title={label}
      disabled={disabled}
      className={toggleGroupButton({
        checked,
        disabled,
        size,
        position,
        isIncomplete,
      })}
    >
      <RadioGroup.Label as="span">{label}</RadioGroup.Label>
    </RadioGroup.Option>
  );
};
