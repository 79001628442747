import axios from 'axios';

import type { Tag } from '../../@types/types';
import { getRequestAuth } from '../helpers/getRequestAuth';

export const fetchTags = async (authToken: string): Promise<Tag[]> => {
  const auth = await getRequestAuth(authToken);

  const response = await axios({
    method: 'get',
    url: `${import.meta.env.VITE_API_URL}/tags-spa-al`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
  });

  return response.data;
};
