import type { FC } from 'react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';
import { useUserStore } from '@Auth/store';
import { AnimateHeight } from '@components/AnimateHeight';
import Avatar from '@components/Avatar';
import DropdownChevron from '@components/DropdownChevron';
import { Show } from '@components/Show';
import { getUserDisplayName } from '@ContractBuilder/modules/comments/utils';
import { getInitials } from '@helpers/getInitials';
import { LogoutIcon } from '@heroicons/react/outline';
import { UserGroupIcon } from '@heroicons/react/solid';
import type { AmplifyUser } from '@root/@types/types';
import { getUserRole, getUserTeams } from '@root/helpers/permissions/utils';
import { useLanguage } from '@root/src/language';

import { buttonClasses, containerClasses, nameClasses } from './classes';

interface MenuSectionUserProps {
  user: AmplifyUser;
  isCollapsible?: boolean;
  displayAvatar?: boolean;
  variant?: 'dark' | 'light';
  containerClassName?: string;
}

export const MenuSectionUser: FC<MenuSectionUserProps> = ({
  containerClassName,
  user,
  isCollapsible,
  variant = 'dark',
  displayAvatar,
}) => {
  const [isOpen, toggleOpen] = useToggle(!isCollapsible);
  const { getContent } = useLanguage({ prefix: 'user.role' });
  const { updatedAt } = useUserStore(({ updatedAt }) => ({ updatedAt }));

  const { initials, teams, name, role } = useMemo(() => {
    const role = getUserRole(user);
    return {
      name: getUserDisplayName(user),
      role: role ? getContent(role) : role,
      teams: getUserTeams(user),
      initials: getInitials(user),
    };
    // eslint-disable-next-line -- Observing only user.username is enough
  }, [user.username, updatedAt]);

  const hasTeams = teams.length > 0;

  return (
    <div
      className={containerClasses({
        isOpen,
        variant,
        className: containerClassName,
      })}
    >
      <div className="flex items-center gap-3">
        <Show when={displayAvatar}>
          <Avatar initials={initials} />
        </Show>
        <div>
          <p className={nameClasses({ variant })}>{name}</p>
          <div className="flex items-center gap-1">
            <p className={'text-xs font-medium'}>{role}</p>
            <Show when={isCollapsible}>
              <DropdownChevron
                open={isOpen}
                onClick={toggleOpen}
                className="cursor-pointer"
                title={isOpen ? 'Collapse' : 'Show teams'}
                data-testid="toggle-teams-and-logout"
              />
            </Show>
          </div>
        </div>
      </div>
      <AnimateHeight isVisible={isOpen}>
        <Show when={hasTeams}>
          <ul className={'flex flex-col gap-2'}>
            {teams.map((team) => (
              <li key={team} className="inline-flex cursor-default items-center gap-2 text-xs " title={team}>
                <UserGroupIcon className="float-left h-3 w-auto flex-shrink-0" />
                <span className="max-w-full truncate">{team}</span>
              </li>
            ))}
          </ul>
        </Show>
        <div className={`${hasTeams && 'mt-6'} text-right`}>
          <Link to="/logout" className="ml-auto">
            <button type="button" className={buttonClasses({ variant })}>
              Logout
              <LogoutIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </button>
          </Link>
        </div>
      </AnimateHeight>
    </div>
  );
};
