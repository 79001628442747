import ItemUsedWithin from '@pages/User/components/ItemUsedWithin';
import type { Column, Filter } from '@root/@types/types';
import { formatDate } from '@root/helpers';
import { BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING, CLAUSE_TYPES } from '@User/Blocks/constants';

export const initialBlocksPageFilters: Filter[] = [
  {
    label: 'Name',
    key: 'name',
    type: 'dropdown',
    placeholder: 'Any',
    options: [],
    selected: [],
  },
  {
    label: 'Layout',
    key: 'type',
    type: 'dropdown',
    placeholder: 'Any',
    options: BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING.map((i) => i.label),
    optionsMapping: BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING,
    selected: [],
  },
  {
    label: 'Clause types',
    key: 'clause_type',
    type: 'dropdown',
    placeholder: 'All clause types',
    options: [...CLAUSE_TYPES],
    selected: [],
  },
  {
    label: 'Show archived',
    key: 'deleted_at',
    type: 'checkbox',
    isChecked: false,
    onChange: () => {},
  },
];

export const initialBlocksTableColumns: Column[] = [
  {
    label: 'Name',
    key: 'name',
    fallbackKeys: ['description', 'heading', 'title'],
    sortable: true,
    normalizer: (fieldValue = '') => fieldValue.toLowerCase(),
  },
  {
    label: 'Layout',
    key: 'type',
    sortable: true,
    formatter: (fielValue) =>
      BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING.find((i) => i.value === fielValue)?.label ?? fielValue,
  },
  {
    label: 'Clause type',
    key: 'clause_type',
    sortable: true,
  },
  {
    label: 'Used within',
    key: 'usedInTemplates',
    render: (item: any) => <ItemUsedWithin item={item} />,
  },
  {
    label: 'Created at',
    key: 'created_at',
    sortable: true,
    formatter: (value: string) => (value ? formatDate(value, 'DD/MM/YYYY HH:mm') : value),
    headingCellClassName: 'w-36',
  },
  {
    label: 'Updated at',
    key: 'updated_at',
    sortable: true,
    formatter: (value: string) => (value ? formatDate(value, 'DD/MM/YYYY HH:mm') : value),
    headingCellClassName: 'w-36',
  },
  {
    label: '',
    key: 'edit',
    headingCellClassName: '!w-20',
    dataCellClassName: '!w-20',
  },
];
