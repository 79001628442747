import { sortByDate } from '@root/helpers';
import type { BaseItem } from '@root/helpers/date/sort-by-date';
import { get } from 'lodash-es';

import type { SortOrder } from '../../@types/types';

const prepareForCaseInsensitiveSort = (field: any) => (typeof field === 'string' ? field.toLowerCase() : field);

export const sortData = <T extends BaseItem>(data: T[], sortOrder: SortOrder) => {
  if (!Array.isArray(data) || !data?.length) return [];

  const { fieldName, sortDirection, defaultFieldValue = '', fieldValueNormalizer } = sortOrder;

  // Handle date sorting separately
  if (['created_at', 'updated_at'].includes(fieldName)) {
    return sortByDate(data, sortDirection, fieldName === 'updated_at' ? 'updated_at' : 'created_at');
  }

  // General sorting
  return data.sort((a, b) => {
    const getFieldValue = (item: T) =>
      prepareForCaseInsensitiveSort(
        fieldValueNormalizer
          ? fieldValueNormalizer(get(item, [fieldName], defaultFieldValue), item)
          : get(item, [fieldName], defaultFieldValue),
      );

    const firstField = getFieldValue(a);
    const secondField = getFieldValue(b);

    if (firstField < secondField) return sortDirection === 'desc' ? 1 : -1;
    if (firstField > secondField) return sortDirection === 'desc' ? -1 : 1;
    return 0;
  });
};
