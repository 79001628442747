import type { FunctionComponent, HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import clsx from 'clsx';

interface CircleIconWrapperProps extends HTMLAttributes<SVGElement> {
  isActive?: boolean;
  icon: FunctionComponent<HTMLAttributes<SVGElement>>;
  onClick: () => void;
  wrapperClassName?: string;
}

export const CircleIconWrapper = forwardRef<HTMLDivElement, CircleIconWrapperProps>(
  ({ isActive = false, icon: IconComponent, onClick, wrapperClassName, ...iconProps }, ref) => {
    return (
      <div
        className={clsx(
          'inline-flex cursor-pointer items-center justify-center rounded-full p-1 text-info-600 transition-all hover:bg-info-100 focus:outline-none',
          isActive && 'hover:bg-primary-100',
          wrapperClassName,
        )}
        onClick={onClick}
        ref={ref}
      >
        <IconComponent
          {...iconProps}
          className={clsx(
            'inline-flex h-8 w-8 cursor-pointer rounded-full p-1 text-info-600 transition-colors hover:bg-info-100 focus:outline-none',
            isActive && 'bg-primary-100 hover:bg-primary-100',
          )}
        />
      </div>
    );
  },
);

CircleIconWrapper.displayName = 'CircleIconWrapper';
