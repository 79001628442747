import React from 'react';
import clsx from 'clsx';

import type { UIComponentBase } from '../../../@types/types';
import { tenantConfig } from '../../../config/tenants';

const AuthLayout: React.FC<UIComponentBase> = ({ children }) => (
  <div className="flex h-screen w-full flex-col items-center justify-center bg-[#f9fafb] bg-cover bg-center !font-['Inter']">
    <div className={clsx('flex w-full max-w-[480px] flex-col overflow-y-auto rounded bg-white pt-8 shadow-xl')}>
      <div className="flex flex-col items-center gap-5">
        <img src={tenantConfig.logo} className=" self-center" alt="Logo" />
        <h1 className="text-xl font-[600]">Contract Builder</h1>
      </div>
      <div className="mt-12 flex-auto px-10 pb-10">{children}</div>
    </div>
  </div>
);

export default AuthLayout;
