import type { FC, ReactNode } from 'react';
import { Fragment, useState } from 'react';
import DropdownChevron from '@components/DropdownChevron';
import IconMdi from '@components/IconMdi';
import { PillBadge } from '@ContractBuilder/components/PillBadge';
import { getChildrenWithPath } from '@helpers/getChildrenWithPath';
import { mdiPlusCircleOutline } from '@mdi/js';
import { doesIncludeSubstring, isNonNullish } from '@root/helpers';
import type { ResolvedSchemaTree } from '@root/helpers/schema';
import { isContentRepeater, isLeafField, isTableRepeater } from '@root/helpers/schema/resolveSchemaTreeFromForm';
import type { SchemaTreeToFlatListResult } from '@root/helpers/schema/schemaTreeToFlatList';
import type { ResolvedSchemaField } from '@root/helpers/schema/types';
import { sectionAnimationProps } from '@WysiwygEditor/components/DatapointInserter/constants';
import type { RenderExpandedProps } from '@WysiwygEditor/components/InsertMenu/components/types';
import { cva } from 'class-variance-authority';
import { AnimatePresence, motion } from 'framer-motion';

import { indentationClasses } from './classes';

const titleClasses = cva(
  [
    //
    'cursor-pointer',
    'h-9',
    'py-2',
    'pr-4',
    'leading-tight',
    'truncate',
    'text-gray-900',
    'bg-gray-50',
    'hover:bg-primary-50',
    'border-b',
    'border-gray-200',
    'flex',
    'items-center',
    'group',
    'text-sm',
    'overflow-x-hidden',
    'w-full',
  ],
  {
    variants: {
      level: {
        0: ['font-bold'],
      },
    },
  },
);

interface DatapointsInserterGroupProps {
  conditionalFields?: SchemaTreeToFlatListResult[];
  forceCollapsed?: boolean;
  forceExpanded?: boolean;
  hasParentMatch?: boolean;
  level?: number;
  onSectionRepeaterInsert?: Function;
  parentSchema?: ResolvedSchemaTree;
  renderItem: RenderExpandedProps['renderItem'];
  schema: ResolvedSchemaTree;
  searchText?: string;
  titleTextClasses?: string;
  sectionRepeaterTitleTextClasses?: string;
  path?: string;
  disableMotion?: boolean;
}
const shouldBeAlwaysOpen = (level: number, forceExpanded: boolean = false) => forceExpanded || level > 1;
const getInitialIsExpanded = (level: number, forceCollapsed: boolean, forceExpanded: boolean = false) =>
  (level === 0 && !forceCollapsed) || shouldBeAlwaysOpen(level, forceExpanded);

const doesIncludeSubstringRecursively = (
  item: ResolvedSchemaTree | ResolvedSchemaField,
  searchText: string,
): boolean => {
  if (doesIncludeSubstring(item.title, searchText)) {
    return true;
  }
  const children = getChildrenWithPath(item, item.id);

  if (children) {
    return children.some((child) => doesIncludeSubstringRecursively(child, searchText));
  }

  return false;
};

export const DatapointsInserterGroup: FC<DatapointsInserterGroupProps> = ({
  conditionalFields = [],
  forceCollapsed = false,
  forceExpanded = false,
  hasParentMatch = false,
  level = 0,
  onSectionRepeaterInsert,
  parentSchema,
  renderItem,
  schema,
  searchText,
  titleTextClasses = 'max-w-[480px] truncate',
  sectionRepeaterTitleTextClasses = 'max-w-[380px] truncate',
  path = '',
  disableMotion = false,
}) => {
  const children = getChildrenWithPath(schema, path);

  const [isExpanded, setIsExpanded] = useState(() => getInitialIsExpanded(level, forceCollapsed, forceExpanded));
  const shouldDisplayTitle = schema.title && (searchText ? doesIncludeSubstringRecursively(schema, searchText) : true);

  const isAlwaysOpen = shouldBeAlwaysOpen(level, forceExpanded);
  const hasChildren = children?.length > 0;

  const handleToggleExpanded = () => {
    if (!isAlwaysOpen) {
      setIsExpanded((prev) => !prev);
    }
  };

  const newParentHasMatch = hasParentMatch || doesIncludeSubstring(parentSchema?.title, searchText);

  if (hasChildren) {
    const inner: ReactNode[] = children
      .sort((a, b) => (a.qid ?? Infinity) - (b.qid ?? Infinity))
      .map((child, idx) => {
        return (
          <DatapointsInserterGroup
            conditionalFields={conditionalFields}
            key={`datapoints-inserter-group-${idx}`}
            level={level + (shouldDisplayTitle ? 1 : 0)}
            onSectionRepeaterInsert={onSectionRepeaterInsert}
            titleTextClasses={titleTextClasses}
            sectionRepeaterTitleTextClasses={sectionRepeaterTitleTextClasses}
            hasParentMatch={newParentHasMatch}
            parentSchema={schema}
            renderItem={renderItem}
            schema={child}
            searchText={searchText}
            path={child.id}
            forceExpanded={forceExpanded}
            disableMotion={disableMotion}
          />
        );
      })
      .filter(isNonNullish);

    const shouldDisplaySectionRepeaterBadge = isTableRepeater(schema['ui:component']) && onSectionRepeaterInsert;
    const title = isContentRepeater(schema['ui:component']) ? schema?.items?.title : schema.title;

    return (
      <Fragment key={schema.title}>
        {shouldDisplayTitle && (
          <button
            className={titleClasses({ level: level as any })}
            title={title}
            onClick={handleToggleExpanded}
            data-testid="toggle-expanded"
          >
            <div className={indentationClasses({ level: level as any, className: ['flex', 'items-center', 'gap-2'] })}>
              {!isAlwaysOpen && (
                <DropdownChevron open={!isExpanded} direction="right" className="inline-flex shrink-0 text-info-600" />
              )}
              <h4
                data-testid={title}
                className={shouldDisplaySectionRepeaterBadge ? sectionRepeaterTitleTextClasses : titleTextClasses}
              >
                {title}
              </h4>
            </div>
            {shouldDisplaySectionRepeaterBadge && (
              <div className="ml-auto flex gap-1">
                <PillBadge
                  content={isContentRepeater(schema['ui:component']) ? 'Repeater' : 'Section'}
                  className="!text-xs font-normal"
                />
                <IconMdi
                  data-testid="insert-section-repeater-button"
                  path={mdiPlusCircleOutline}
                  className={'text-info-500  hover:text-primary-500'}
                  onClick={() => onSectionRepeaterInsert(schema, path)}
                />
              </div>
            )}
          </button>
        )}
        {isAlwaysOpen || disableMotion ? (
          inner
        ) : (
          <AnimatePresence>{isExpanded && <motion.div {...sectionAnimationProps}>{inner}</motion.div>}</AnimatePresence>
        )}
      </Fragment>
    );
  }

  if (isLeafField(schema) && (doesIncludeSubstring(schema.title, searchText) || newParentHasMatch)) {
    return (
      <Fragment>
        {renderItem({
          value: path,
          label: schema.title,
          schema,
          level,
          'data-testid': schema.title,
          isConditional: false,
        })}
      </Fragment>
    );
  }
};
