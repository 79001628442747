import { getRequestAuth } from '@helpers/getRequestAuth';
import type { MrcExtractionResult } from '@root/@types/api';
import type { MrcExtractionResultItem } from '@root/@types/types';
import axios from 'axios';

const MRC_DOCUMENT_UPLOAD_URI = 'data-extraction';
const SUBMISSIONS_URI = 'submissions-spa-al';

export interface GetDataExtractionResultResponse {
  data: MrcExtractionResultItem[];
  fileName: string;
  fileUrl: string;
  status: MrcExtractionResult;
}

export const getDataExtractionResult = async (submissionId: string, extractionId: string) => {
  const auth = await getRequestAuth();
  const url = [import.meta.env.VITE_API_URL, SUBMISSIONS_URI, submissionId, MRC_DOCUMENT_UPLOAD_URI, extractionId]
    .filter(Boolean)
    .join('/');

  const response = await axios<GetDataExtractionResultResponse>({
    method: 'get',
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
  });

  return response.data;
};
