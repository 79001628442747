import { getRequestAuth } from '@helpers/getRequestAuth';
import axios from 'axios';

import { getCurrentDocumentApiUrl } from './getCurrentDocumentApiUrl';

const ATTACHMENT_DOCUMENT_UPLOAD_URI = 'attachments';

interface AttachmentDocumentPresignedUrlResponse {
  data: {
    url: string;
  };
}

export const getAttachmentDocumentPresignedUrl = async (
  fileName: string,
  name: string,
  size: number,
  templateId?: string,
  submissionId?: string,
  endorsementId?: string,
) => {
  const auth = await getRequestAuth();

  const url = [getCurrentDocumentApiUrl(templateId, submissionId, endorsementId), ATTACHMENT_DOCUMENT_UPLOAD_URI].join(
    '/',
  );

  if (!url) {
    return;
  }

  const response = await axios<AttachmentDocumentPresignedUrlResponse>({
    method: 'post',
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
    data: {
      fileName,
      name,
      size,
    },
  });

  return response;
};
