import type { FC, KeyboardEventHandler } from 'react';
import React, { useMemo, useRef, useState } from 'react';
import { getNewInputValueFromEvent } from '@components/InputCalendar/utils/get-new-input-value-from-event';
import { InputField, type InputFieldProps } from '@components/InputField';
import InputReadOnly from '@components/InputReadOnly';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { datapointFieldSelector } from '@ContractBuilder/utils/datapoint-field-selector';
import type { UIOnChangeFn } from '@root/@types/types';
import { formatTime } from '@root/helpers';
import { TimePicker } from 'antd';
import type { TimePickerProps } from 'antd/es/time-picker';
import { cva } from 'class-variance-authority';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import './InputTime.css';

dayjs.extend(customParseFormat);

const classes = cva(
  [
    'focus:shadow-outline',
    'border-info-300',
    'text-info-700',
    'shadow-sm',
    'hover:border-info-300',
    'focus:border-primary-500',
    'focus:ring-primary-500',
  ],
  {
    variants: {
      isIncomplete: { true: 'field-incomplete' },
    },
  },
);

interface InputTimeSchemaProps extends Omit<InputFieldProps, 'size'>, Omit<TimePickerProps, 'onChange'> {
  isReadOnly: boolean;
  item: any;
  name: string;
  ownKey: string;
  onChange: UIOnChangeFn<number | string>;
}

const isNavigationKey = (key: string) => key.length > 1;

const InputTimeSchema: FC<InputTimeSchemaProps> = ({
  cdrId,
  cdrName,
  className,
  descriptionText,
  errors,
  id,
  isDisabled,
  isReadOnly,
  labelIcon,
  labelText,
  mrcId,
  name,
  hideErrors,
  onChange,
  shortName,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputFieldRef = useRef<HTMLDivElement>(null);

  const { ownKey, isRequired } = props;
  const selector = useMemo(() => datapointFieldSelector({ ownKey, isRequired, errors }), [ownKey, isRequired, errors]);
  const { value = null, isIncomplete } = useDatapointsStore(selector);

  const parsedValue = value ? dayjs(value, 'HH:mm:ss.SSSZ') : null;
  const formattedValue = parsedValue ? formatTime(value) : undefined;

  const handleChange = (time: Dayjs | null) => {
    onChange(time?.format('HH:mm:ss.SSSZ'), name);
  };

  const handleSelect = (time: Dayjs | null) => {
    onChange(time?.format('HH:mm:ss.SSSZ'), name);
  };

  const handleInputChange: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (!/[0-9:]/.test(event.key) && !isNavigationKey(event.key)) {
      return;
    }

    const newValue = getNewInputValueFromEvent(event);
    const currentInputValue = event.currentTarget.value;
    const currentInputLength = currentInputValue.length;
    const newInputLength = newValue.length;

    if (currentInputLength === 5 && !isNavigationKey(event.key)) {
      return event.preventDefault();
    }

    if (newInputLength === 2 && !isNavigationKey(event.key)) {
      event.currentTarget.value = `${newValue}:`;
      return event.preventDefault();
    }

    if (newInputLength === 5) {
      const newValue = getNewInputValueFromEvent(event);
      const date = dayjs(newValue, 'HH:mm');

      onChange(date.format('HH:mm:ss.SSSZ'), name);
      return event.currentTarget.blur();
    }
  };

  return (
    <InputField
      cdrId={cdrId}
      cdrName={cdrName}
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      id={id ?? name}
      isDisabled={isReadOnly}
      isRequired={props.isRequired}
      labelIcon={labelIcon}
      labelText={labelText}
      mrcId={mrcId}
      name={name}
      hideErrors={hideErrors}
      ref={inputFieldRef}
      shortName={shortName}
      isIncomplete={isIncomplete}
    >
      {isReadOnly && (
        <InputReadOnly
          className={className}
          defaultValue={formattedValue}
          key={value}
          size="md"
          value={formattedValue}
        />
      )}
      {!isReadOnly && (
        <TimePicker
          {...props}
          data-testid="time"
          aria-readonly={isReadOnly}
          changeOnBlur
          className={classes({ isIncomplete })}
          defaultValue={dayjs('00:00', 'HH:mm')}
          disabled={isDisabled || isReadOnly}
          format="HH:mm"
          getPopupContainer={() => inputFieldRef?.current ?? document.body}
          onChange={handleChange}
          onKeyDown={handleInputChange}
          onOpenChange={setIsOpen}
          onSelect={handleSelect}
          open={isOpen}
          placeholder="HH:MM"
          ref={inputRef}
          showNow={false}
          size="large"
          value={formattedValue ? dayjs(formattedValue, 'HH:mm') : parsedValue}
        />
      )}
    </InputField>
  );
};

export default InputTimeSchema;
