import type { SvgIconName } from '@components/Svg';
import type { FeatureFlag } from '@hooks/useFeatureFlags';
import type { AmplifyUser, User } from '@root/@types/types';
import { getUserRole } from '@root/helpers/permissions/utils';

import Nav, { config } from '../routes';

type Menu = {
  path: Nav;
  icon: SvgIconName;
  allowedRoles?: string[];
  featureFlag?: FeatureFlag;
};

type MenuNavigation = Pick<Menu, 'path' | 'icon'> & {
  name: string;
};

export const menu: Menu[] = [
  { path: Nav.Submissions, icon: 'submissions' },
  { path: Nav.Templates, icon: 'templates', allowedRoles: ['admin', 'superadmin'] },
  { path: Nav.Blocks, icon: 'blocks', allowedRoles: ['admin', 'superadmin'] },
  { path: Nav.Branding, icon: 'photo', featureFlag: 'Branding', allowedRoles: ['admin', 'superadmin'] },
  { path: Nav.Tags, icon: 'tags', allowedRoles: ['admin', 'superadmin'] },
  { path: Nav.Users, icon: 'users', allowedRoles: ['superadmin'] },
];

export const createMenuForUser = (
  user: User | AmplifyUser | null,
  getFeatureFlagState: (featureFlag: FeatureFlag) => boolean | undefined,
): MenuNavigation[] => {
  if (!user) {
    return [];
  }

  const userRole = getUserRole(user);

  if (!userRole) {
    return [];
  }

  const filtered = menu.filter(({ allowedRoles, featureFlag }) => {
    if (featureFlag && !getFeatureFlagState(featureFlag)) {
      return false;
    }

    if (!allowedRoles) {
      return true;
    }

    return allowedRoles.includes(userRole);
  });

  return filtered.map(({ path, icon }) => ({ path, icon, name: config[path].title }));
};
