export const ALL_CLASSES_OF_BUSINESS = [
  'Accident and health',
  'Health',
  'Group accident and health',
  'Personal accident and health',
  'Personal accident',
  'Sickness',
  'Agriculture and forestry',
  'Farmowners',
  'Livestock and bloodstock',
  'Auto or Motor',
  'Auto physical damage and liability',
  'Auto physical damage',
  'Auto liability',
  'Motor',
  'Uninsured Loss',
  'Aviation',
  'Aviation liability',
  'Aviation personal accident',
  'Aviation all classes',
  'Aviation cargo',
  'Aviation hull',
  'Airline liability',
  'Aviation Hull & Liability',
  'General aviation',
  'General aviation liability',
  'Aviation manufacturers',
  'Aviation Spares',
  'Aviation products',
  'Space and Satellites',
  'Casualty or liability',
  'Employers liability',
  'Excess liability',
  'General liability',
  'Other liability',
  'Storage liability',
  'Environmental/pollution liability',
  'Intellectual Property',
  'Professional Liability',
  'Personal liability',
  'Medical malpractice',
  'Umbrella liability',
  'Product liability',
  'Construction Liability',
  'Events and Media',
  'Hospitals / Healthcare Institutions',
  "Workers' compensation",
  'Contingency liability',
  'Contingency/pecuniary loss',
  'Guarantee - loss of profits',
  'Business Interruption (Business Income)',
  'Consequential Loss',
  'Commercial loss of profits',
  'Cyber',
  'Energy',
  'Energy Liability',
  'Energy operators',
  'Energy Construction',
  'Nuclear',
  'Engineering',
  'Contractors risks',
  'Boiler and machinery',
  'Construction',
  'Financial',
  'Liquidated damages',
  'Pecuniary loss',
  'Financial guarantee',
  'Financial Services',
  'Personal - Financial',
  'Contract frustration',
  'Credit and Bonding',
  'Credit accident and health',
  'Fidelity',
  'Surety bonding',
  'Forgery',
  'E-commerce',
  'Commercial Reinsurance to Close',
  'Stop loss',
  'Life',
  'Marine',
  'Marine cargo',
  'Marine hull',
  'Marine - Liability',
  'Marine - general',
  'Inland marine',
  'Ocean marine',
  'Political risks',
  'Property',
  'Homeowners',
  'Commercial multiple peril',
  'Commercial single peril',
  'Multilines',
  'Railways',
  'Specie',
  'Baggage',
  'Electronics',
  'Music instruments',
  'Allied lines',
  'Burglary',
  'Robbery',
  'Difference in conditions',
  'Glass',
  'Wood infestation',
  'Fire',
  'Wind storm',
  'Flood',
  'Earthquake',
  'Hail',
  'Natural catastrophes',
  'Water damage',
  'Whole account',
  'Various',
];
