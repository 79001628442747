import type { LibraryBlockType } from '@ContractBuilder/types';
import { getRequestAuth } from '@helpers/getRequestAuth';
import axios from 'axios';

type Params = { [key: string]: string };
const getParams = (searchParams: URLSearchParams, params?: Params) => (paramKey: string) =>
  params?.[paramKey] ?? searchParams.get(paramKey);

export const fetchBlocks = async (
  authToken: string,
  params?: Params,
): Promise<{ data: LibraryBlockType[]; totalAmount: number; names: string[] }> => {
  const auth = await getRequestAuth(authToken);

  const { searchParams } = new URL(location.href);
  const getParamValue = getParams(searchParams, params);
  const page = getParamValue('page');
  const limit = getParamValue('limit');
  const sortBy = getParamValue('sortBy');
  const sortDirection = getParamValue('sortDirection');
  const filters = getParamValue('filters');
  const search = getParamValue('search') ?? '';

  const response = await axios({
    method: 'get',
    url: `${import.meta.env.VITE_API_URL}/blocks${auth.authProvider === 'al' ? '-spa-al' : ''}`,
    params: { page, limit, sortBy, sortDirection, filters, search },
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
  });

  return response.data;
};
