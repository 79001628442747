import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import type {
  CheckboxFilter,
  DropdownFilter,
  Filter,
  SortFieldValueNormalizerFn,
  SortOrder,
  Tag,
  Team,
  ToggleSortOrderDirectionFn,
} from '@root/@types/types';
import { tenantConfig } from '@root/config/tenants';
import { sortData } from '@root/helpers/pagination';
import { useTags } from '@src/hooks';
import { isBlocksPath } from '@utils/app-paths';
import { get } from 'lodash-es';
import qs from 'qs';
import useDeepCompareEffect from 'use-deep-compare-effect';

const teams = tenantConfig.teams ?? [];

const applyCheckboxFilter = (filter: CheckboxFilter, item: any) =>
  filter.isChecked ? true : !get(item, filter.key, false);

const applyTagsFilter = (filter: DropdownFilter, item: any, tags: Tag[]) =>
  filter.selected.some((element) =>
    get(item, filter.key, [])
      .map((tagId: string) => tags.find((tag: Tag) => tag.id === tagId)?.label)
      .includes(element),
  );

const applyTeamsFilter = (filter: DropdownFilter, item: any, teams: Team[]) =>
  filter.selected.some((element) =>
    get(item, filter.key, [])
      .map((teamId: number) => teams.find((team: Team) => team.id === teamId)?.name)
      .includes(element),
  );

const applyDropdownFilter = (filter: DropdownFilter, item: any) => {
  const selectedFilter =
    filter?.optionsMapping === undefined
      ? filter.selected
      : filter?.optionsMapping?.filter((i) => filter?.selected?.includes(i.label)).map((i) => i.predicate ?? i.value);
  if (filter?.isPredicateFilter && filter?.optionsMapping && filter.selected.length > 0) {
    return selectedFilter.find((predicate: any) => predicate(item)) !== undefined;
  }
  return selectedFilter.includes(get(item, filter.key, ''));
};

const applyFilter = (filter: Filter, item: any, tags: Tag[]) => {
  if (filter.type === 'checkbox') {
    return applyCheckboxFilter(filter, item);
  }
  if (!filter.selected || filter.selected.length === 0) {
    return true;
  }
  if (filter.key === 'tags') {
    return applyTagsFilter(filter, item, tags);
  }
  if (filter.key === 'teams') {
    return applyTeamsFilter(filter, item, teams);
  }
  return applyDropdownFilter(filter, item);
};

const getFilteredData = (data: any[], filters: Filter[], tags: Tag[]) => {
  let filteredData = Array.isArray(data) ? data : [];
  filters?.forEach((filter) => {
    filteredData = filteredData?.filter((item: any) => applyFilter(filter, item, tags));
  });
  return filteredData;
};

const getFetchedAt = () => {
  const newDate = new Date().getMilliseconds();
  return newDate;
};

export const useDataQuery = (
  queryKey: string,
  initialFilters: Filter[] = [],
  fetchData: (authToken: string) => Promise<any>,
  onSuccess: (data: any) => any = () => {},
  initialSortOrder: SortOrder = {
    fieldName: 'created_at',
    sortDirection: 'desc',
    defaultFieldValue: '',
  },
  enabled = true,
) => {
  const [filters, setFilters] = useState(initialFilters);
  const [sortOrder, setSortOrder] = useState<SortOrder>(initialSortOrder);
  const [shouldFetchData, setShouldFetchData] = useState<boolean>(true);
  const [fetchedAt, setFetchedAt] = useState<number>(getFetchedAt);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const tags = useTags(filters, setFilters, ['templates', 'submissions'].includes(queryKey));
  const isBlocks = isBlocksPath();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setShouldFetchData(true);
  }, [searchParams]);

  useEffect(() => {
    if (shouldFetchData) {
      setFetchedAt(getFetchedAt());
    }
  }, [shouldFetchData]);

  useEffect(() => {
    const clonedFilteredData = [...filteredData];
    sortData(clonedFilteredData, sortOrder);
    setFilteredData(clonedFilteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only care about `sortOrder` here
  }, [sortOrder]);

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { data, isFetching, status } = useQuery(
    [queryKey, fetchedAt],
    () => fetchData(queryParams.authToken as string),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShouldFetchData(false);
        onSuccess(data);
      },
      enabled,
    },
  );

  const parsedFiltersDependency = filters?.map((filter) =>
    filter.type === 'checkbox' ? filter.isChecked : filter.selected,
  );

  useDeepCompareEffect(() => {
    if (isBlocks) {
      setFilteredData(data?.data ?? []);
      return;
    }
    if (data) {
      setFilteredData(getFilteredData(data, filters, tags));
    }
    // eslint-disable-next-line -- `filters` is included in `parsedFiltersDependency`
  }, [parsedFiltersDependency, data, tags]);

  const toggleSortOrderDirection: ToggleSortOrderDirectionFn =
    (fieldName: string, fieldValueNormalizer?: SortFieldValueNormalizerFn) => () => {
      const newSortDirection = fieldName !== sortOrder.fieldName || sortOrder.sortDirection !== 'desc' ? 'desc' : 'asc';
      setSortOrder({ ...sortOrder, fieldName, fieldValueNormalizer, sortDirection: newSortDirection });
      sortData(data, sortOrder);
    };

  sortData(data, sortOrder);

  const onFilterChange = (index: number, value?: Filter[]) => {
    const clonedFilters = filters.map((currentFilter, currentIndex) => {
      if (currentIndex === index) {
        return { ...currentFilter, selected: value };
      }
      return currentFilter;
    });
    setFilters(clonedFilters as Filter[]);
  };

  const getClearedFilters = () => {
    const clearedFilters = filters.map((filter) => ({ ...filter, selected: undefined })) as Filter[];
    setFilters(clearedFilters);

    return clearedFilters;
  };

  return {
    data: filteredData,
    filters,
    isFetching,
    getClearedFilters,
    onFilterChange,
    setFilters,
    setShouldFetchData,
    sortOrder,
    status,
    tags,
    toggleSortOrderDirection,
  };
};
