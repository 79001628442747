import { getRequestAuth } from '@helpers/getRequestAuth';
import axios from 'axios';

import type { TransformedCognitoUser } from '../../@types/cognito';

export const createUser = async (
  data: Pick<TransformedCognitoUser, 'first_name' | 'last_name' | 'email' | 'role' | 'teams'>,
  authToken: string,
): Promise<Pick<TransformedCognitoUser, 'id'>> => {
  const auth = await getRequestAuth(authToken);

  const response = await axios({
    method: 'post',
    url: `${import.meta.env.VITE_API_URL}/users${auth.authProvider === 'al' ? '-spa-al' : ''}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
    data,
  });

  return response.data;
};
