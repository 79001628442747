import type { ReactNode } from 'react';
import React from 'react';
import { InputField, type InputFieldProps } from '@components/InputField';
import { getValueFromObjOrSimple, getValueOrUndefined } from '@helpers/getValue';

import type { RenderLabelArgs } from './InputRadio';
import InputRadio from './InputRadio';

type Value = string | number;
type Option = {
  id: string;
  value: Value;
  labelText: string;
  descriptionText: string;
};

export type RadioOption = Value | Option;

export interface InputRadioGroupProps extends InputFieldProps {
  isReadOnly?: boolean;
  onChange?: (value: any) => void;
  options?: Array<RadioOption>;
  renderOption?: (props: RenderLabelArgs) => ReactNode;
  value?: Value;
  item: {
    shortName?: string;
  };
}

const InputRadioGroup: React.FC<InputRadioGroupProps> = ({
  cdrId,
  cdrName,
  className,
  descriptionText,
  errors,
  helperText,
  id,
  isReadOnly,
  isRequired,
  item,
  labelIcon,
  labelText,
  mrcId,
  name,
  options,
  value,
  isIncomplete,
  onChange,
  renderOption,
}) => (
  <InputField
    cdrId={cdrId}
    cdrName={cdrName}
    className={className}
    descriptionText={descriptionText}
    errors={errors}
    helperText={helperText}
    id={id ?? name}
    isRequired={isRequired}
    labelIcon={labelIcon}
    labelText={labelText}
    mrcId={mrcId}
    name={name}
    shortName={item?.shortName}
  >
    {options?.map((option) => (
      <InputRadio
        isIncomplete={isIncomplete}
        className={'py-1'}
        descriptionText={getValueOrUndefined(option, 'descriptionText')}
        errors={!!errors}
        id={getValueOrUndefined(option, 'id') || [name, option].join('.')}
        isChecked={value ? value === getValueFromObjOrSimple(option, 'value') : undefined}
        isRequired={isRequired}
        key={getValueFromObjOrSimple(option, 'value')}
        labelText={getValueFromObjOrSimple(option, 'labelText')}
        name={name}
        onChange={onChange}
        value={getValueFromObjOrSimple(option, 'value')}
        isDisabled={isReadOnly}
        readOnly={isReadOnly}
        renderLabel={renderOption ?? undefined}
      />
    ))}
  </InputField>
);

export default InputRadioGroup;
