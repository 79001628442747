import { useQuery } from 'react-query';
import qs from 'qs';

import type { DynamoDBLibraryBlock, UseQueryRefetchFn, UseQueryStatus } from '../../@types/types';
import { fetchBlock } from '../queries';

interface BlockLibraryHook {
  data?: DynamoDBLibraryBlock;
  status: UseQueryStatus;
  isFetching: boolean;
  refetch: UseQueryRefetchFn<any, any>;
}

export const useBlockLibrary = (id: string): BlockLibraryHook => {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { data, status, isFetching, refetch } = useQuery(
    ['library-block', id],
    () => fetchBlock(id, queryParams.authToken as string),
    {
      refetchOnWindowFocus: true,
    },
  );

  return {
    data: data?.data,
    status,
    isFetching,
    refetch,
  };
};
