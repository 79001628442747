import { isEqual } from 'lodash-es';
import { createWithEqualityFn } from 'zustand/traditional';

type UiState = {
  isBlockUsedInTemplatesModalOpen: boolean;
  isPDFPreviewOpen: boolean;
  reset: () => void;
  setBlockUsedInTemplatesModalVisibility: (visibility: boolean) => void;
  showHiddenFields: boolean;
  togglePDFPreview: () => void;
  toggleShowHiddenFields: () => void;
};

export const useUIStore = createWithEqualityFn<UiState>(
  (set) => ({
    isBlockUsedInTemplatesModalOpen: false,
    isPDFPreviewOpen: false,
    reset: () => set({ isBlockUsedInTemplatesModalOpen: false, showHiddenFields: false }),
    setBlockUsedInTemplatesModalVisibility: (value) => set({ isBlockUsedInTemplatesModalOpen: value }),
    showHiddenFields: false,
    togglePDFPreview: () =>
      set((state) => {
        const newValue = !state.isPDFPreviewOpen;
        return { isPDFPreviewOpen: newValue };
      }),
    toggleShowHiddenFields: () => set((state) => ({ showHiddenFields: !state.showHiddenFields })),
  }),
  isEqual,
);
