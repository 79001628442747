import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import SignInButton from '@components/auth/SignInButton';
import { Show } from '@components/Show';
import { useForm } from '@hooks/form';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { get } from 'lodash-es';
import * as yup from 'yup';

import Button from '../../components/Button';
import Form from '../../components/Form';
import InputText from '../../components/InputText';
import Nav from '../../routes';

import { isPasswordExpired, PasswordExpiredBanner } from './components/PasswordExpiredBanner';
import AuthLayout from './AuthLayout';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getFeatureFlagState = useFeatureFlags();
  const isSSOEnabled = getFeatureFlagState('SSO');
  const externalIdentityProvider = import.meta.env?.VITE_EXTERNAL_IDENTITY_PROVIDER;

  const { getFieldProps, canSubmit, onSubmit, formError } = useForm<{ email: string; password: string }>({
    initialState: { email: '', password: '' },
    onSubmit: async (formValues) => {
      const user = await Auth.signIn(formValues.email.trim(), formValues.password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return navigate(Nav.SetupPassword, { state: formValues });
      }
      const pathName = get(location, 'state.from.pathname', '').trim();
      return navigate(pathName ? pathName : Nav.Submissions);
    },
    validationSchema: yup.object().shape({
      email: yup.string().required(),
      password: yup.string().required(),
    }),
  });

  const handleSubmit = async () => {
    await onSubmit();
  };

  const isPasswordExpiredError = isPasswordExpired(formError);
  const isPasswordError = isPasswordExpiredError ? false : formError?.match(/password/gi);
  const isEmailError = formError?.match(/email|user/gi);

  return (
    <AuthLayout>
      <Show when={isPasswordExpiredError}>
        <PasswordExpiredBanner />
      </Show>
      <Form onSubmit={handleSubmit}>
        <InputText
          errors={isEmailError ? formError : undefined}
          className="mb-6"
          labelText="Email address"
          placeholder="email@example.com"
          isPublic
          {...getFieldProps('email')}
        />

        <InputText
          className="mb-6"
          errors={isPasswordError ? formError : undefined}
          labelText="Password"
          isPublic
          link={
            <Link className="cb--link font-medium text-primary-500 " to={'/forgot-password'}>
              Forgot password?
            </Link>
          }
          type="password"
          {...getFieldProps('password')}
        />

        <Button kind="primary" type="submit" isDisabled={!canSubmit} className="h-10 w-full">
          Sign in
        </Button>
        <Show when={isSSOEnabled && externalIdentityProvider}>
          <p
            className="mb-5 mt-5 w-full border-b border-info-400 text-center text-sm font-medium text-info-600"
            style={{ lineHeight: '0.1em' }}
          >
            <span className="bg-white px-3">or</span>
          </p>
          <SignInButton signInName="Microsoft" customProvider={externalIdentityProvider} />
        </Show>
      </Form>
      <br />
    </AuthLayout>
  );
};

export default Login;
