import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import type { EndorsementData } from '@ContractBuilder/types';
import qs from 'qs';

import type { UseQueryRefetchFn, UseQueryStatus } from '../../@types/types';
import { fetchEndorsements } from '../queries';

interface EndorsementDataHook {
  data: EndorsementData[] | undefined;
  status: UseQueryStatus;
  isFetching: boolean;
  refetch: UseQueryRefetchFn<any, any>;
}

export const useEndorsementData = (options: UseQueryOptions = {}): EndorsementDataHook => {
  const location = useLocation();
  const { id = '' } = useParams<{ id: string }>();

  const queryParams: { authToken?: string } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { data, status, isFetching, refetch } = useQuery(
    ['endorsements', id],
    () => fetchEndorsements(id, queryParams?.authToken ?? ''),
    {
      // @ts-expect-error
      refetchOnWindowFocus: false,
      ...options,
    },
  );

  return {
    data: data as EndorsementData[],
    status,
    isFetching,
    refetch,
  };
};
